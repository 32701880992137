import moment from 'moment'

import i18n from 'i18n'

/**
 * Provides the date format based on the current language.
 * If the language is set to 'pt-BR', the format will be 'DD/MM/YYYY'.
 * Otherwise, the format will be 'MM/DD/YYYY'.
 * @returns The date format as a string.
 */
export const getDateFormat = (): string => {
  return i18n.language === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
}

/**
 * Converts a timestamp to a formatted date string.
 * @param timestamp - The timestamp to convert.
 * @returns The formatted date string.
 * @example timestampToDate(1614476400) -> '28/02/2021'
 */
export const timestampToDate = (timestamp: number): string => {
  return moment.unix(timestamp).utc().format(getDateFormat())
}

/**
 * Converts a date to a Unix timestamp.
 * @param dateInput - The date to convert. It can be a string, number, or Date object.
 * @returns The Unix timestamp representing the given date. If the conversion fails, it returns 0.
 * @example dateToTimestamp('28/02/2021') -> 1614476400
 */
export const dateToTimestamp = (dateInput: string | number | Date): number => {
  const dateIsString = typeof dateInput === 'string'
  const dateIsNumber = typeof dateInput === 'number'
  const dateIsDate = dateInput instanceof Date

  let dateMoment

  if (dateIsString) dateMoment = moment.utc(dateInput, getDateFormat())
  if (dateIsNumber || dateIsDate) dateMoment = moment.utc(dateInput)

  return dateMoment?.unix() || 0
}

export const getFullYear = (date: string): number => {
  return moment(date, getDateFormat()).year()
}

export const handleDateStr = (value: string): string | undefined => {
  const hasNonNumeric = /[^0-9/]/.test(value)
  if (value === '//' || hasNonNumeric) return undefined
  return value
}

export const calculateAge = (timestamp: number): number => {
  const birthDate = moment.unix(timestamp)
  return moment().diff(birthDate, 'years')
}
