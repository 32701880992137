const en: AppLocale = {
  languages: {
    'pt-BR': 'Português',
    'en-US': 'English'
  },
  notifications: {
    lugarh: {
      error: {
        'An error occurred while processing your request':
          'An error occurred while processing your request',
        'Not authorized, please check the workspace':
          'Not authorized, please check the workspace',
        permission: 'You do not have permission to access this resource.'
      },
      success: {
        'Your request was successfully completed':
          'Your request was successfully completed'
      }
    }
  },
  goHomeButton: {
    lugarh: {
      goHome: 'Go to home page'
    }
  },
  errors: {
    onlySpaces: 'Cannot contain only whitespace.',
    minBudget: 'The minimum value is 650',
    errorTitle: 'Error',
    forbbidenError: 'You do not have permission to access this resource.',
    maxLength: 'Only 2,000 characters are allowed',
    requiredField: 'Required field',
    specialCharacters: 'Only letters and numbers are allowed',
    dateInvalidFormat: 'Please enter your date of birth in the correct format.',
    deadlineInvalidFormat: 'Please enter a valid deadline.',
    futureDate:
      'The birth date cannot be in the future. Please enter a valid date.',
    invalidBirthdate:
      'The provided birth date is out of the acceptable range. Please ensure you enter a valid date.',
    invalidStartDateFormat:
      'Please enter the start date in the correct format.',
    futureStartDate: 'The start date cannot be in the future.',
    invalidStartDate:
      'The provided start date is out of the acceptable range. Please ensure you enter a valid date.',
    invalidEndDateFormat: 'Please enter the end date in the correct format.',
    invalidEndDateOrder:
      'The end date of the course must be after the start date.',
    invalidEndDate:
      'The provided end date is out of the acceptable range. Please ensure you enter a valid date.',
    futureEndDate: 'The end date of the course cannot be in the future.',
    expiredtoken: 'Your session has expired',
    emailInvalid: 'Invalid email!',
    fieldInvalid: 'Invalid field!',
    fieldMinLength: 'Must be at least 3 characters long.',
    onRequestFail: 'An error occurred while fetching data, please try again',
    addNoteError: 'Error adding note, please try again',
    addReview: 'Error adding note',
    cnpj: 'The CNPJ format is incorrect',
    cnpjMaxLength: 'Reached the maximum limit of 18 characters.',
    cpfCnpjInvalid: 'The entered number is invalid.',
    cpfCnpjMinLength: 'Must be at least 14 characters long.',
    cpfCnpjMaxLength: 'Reached the maximum limit of 18 characters.',
    passwordMin: 'The password must be at least 6 characters long.',
    passwordConfirm: 'Passwords must match!',
    finalizeCompanyRegistration:
      'To access this resource, you need to complete the company registration.',
    invalidSlug: 'Career page link is invalid!',
    linkAvailable: 'Link Available!',
    linkUnavailable: 'Link Unavailable',
    invalidComapanyName: 'Company name cannot contain special characters',
    applicationError: 'This record already exists in our databases',
    timeoutError: 'The server took too long to respond. Please try again',
    emptyCity: 'The locality field must be filled in.',
    limitReached: 'This resource has reached the plan limit.',
    creditCardNumberInvalid: 'Invalid card number',
    creditCardCvvInvalid: 'Invalid CVV',
    creditCardExpirationMonthInvalid: 'Invalid month',
    creditCardExpirationYearInvalid: 'Invalid year',
    hiringWorkflowNameMaxLength: 'Reached the maximum limit of 80 characters.',
    hiringWorkflowDescriptionMaxLength:
      'Reached the maximum limit of 300 characters.',
    downloadAttachedFile: 'The file does not exist or is no longer available',
    serverError: 'The server was unable to complete your request.'
  },
  success: {
    candidateMoved: 'Candidate moved successfully',
    noteAdded: 'Note added successfully',
    addReview: 'Note added successfully',
    applicationSuccess: 'Success',
    jobMoved: 'Job moved successfully',
    jobDuplicated: 'Job duplicated successfully'
  },
  cookie: {
    message:
      'We use cookies to help personalize content and provide a more secure experience. We do not store personal data. By navigating the pages, you agree to our Privacy Policy.',
    buttonAccept: 'Accept',
    buttonRefuse: 'Refuse'
  },
  common: {
    current: 'Current',
    gender: {
      male: 'Male',
      female: 'Female',
      other: 'Other'
    },
    civilStatus: {
      single: 'Single',
      married: 'Married',
      divorced: 'Divorced',
      widowed: 'Widowed',
      other: 'Other'
    },
    applyToJob: 'Apply for Job'
  },
  createWorkflow: {
    create: 'Create Hiring Workflow',
    update: 'Update Hiring Workflow'
  },
  home: {
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description: 'The next generation of candidate relationship management.'
    },
    content: 'Edit the src/Home.tsx file and save to update'
  },
  uploadBox: {
    minSizeRecommended: 'recommended size 400 x 50 px',
    logo: 'Logo',
    suggestedSize: 'Suggested size'
  },
  searchBar: {
    allCandidates: 'View all candidates',
    allJobs: 'View all jobs',
    placeholder: 'Search for candidates, jobs, and tags',
    results: {
      candidates: 'Candidates found',
      jobs: 'Jobs found'
    },
    searching: 'Searching...',
    notFound: 'No results found',
    appliedFor: 'applied for {{number}} jobs',
    candidatesNumber: '{{number}} candidates',
    createdAt: 'Created on {{date}}',
    candidates: 'candidates'
  },
  header: {
    about: 'About Us',
    terms: 'Terms of Use',
    signup: 'Create Account'
  },
  headerSettings: {
    workspaces: 'Workspaces',
    myProfile: 'My Profile',
    profileEdit: 'Edit Profile',
    company: 'Company',
    subscriptionSettings: 'Subscription Settings',
    companySettings: 'Company Settings',
    jobPostings: 'Job Management',
    admission: 'Admission',
    hiringWorkflows: 'Hiring Workflows',
    changeCompany: 'Change Company',
    changeWorkspace: 'Change Workspace',
    manageWorkspace: 'Manage Workspace',
    manageTeam: 'Manage Team',
    language: 'Language',
    english: 'English',
    portuguese: 'Portuguese',
    spanish: 'Spanish',
    session: 'Session',
    leave: 'Logout',
    hello: 'Hi',
    sections: {
      dashboard: 'Dashboard',
      jobs: 'Jobs',
      candidates: 'Candidates',
      settings: 'Settings',
      admission: 'Candidate Admission',
      newFlag: 'NEW'
    },
    helpButtonDescription: 'Help',
    helpButtonSubject: 'Help with Candidate Management'
  },
  user: {
    role: {
      admin: 'Administrator',
      admin_rocketmat: 'Administrator',
      manager: 'Manager',
      recruiter: 'Recruiter',
      recruiter_webtactics: 'Recruiter Webtactics'
    },
    indicationButton: 'Refer Recrud',
    copyLinkSuccessMessage: 'Link copied successfully',
    here: 'Here'
  },
  router: {
    pageNotFound: 'Page not found. Please contact support.'
  },
  login: {
    lugarh: {
      title:
        '[[span]] Revolutionize your HR process[[/span]] [[span.highlight]]end-to-end[[/span]] [[span]]in one Lugarh[[/span]]',
      subtitle:
        'In addition to a powerful Recruitment and Selection tool, we offer a complete ecosystem for',
      itemOne: 'Onboarding',
      itemTwo: 'People Management',
      itemThree: 'HR BPO',
      specialist: 'Talk to a specialist',
      form: {
        title: 'Sign in to your account',
        email: {
          placeholder: 'Email'
        },
        password: {
          placeholder: 'Password',
          show: 'Show',
          hide: 'Hide'
        },
        keepConnected: {
          label: 'Keep me signed in'
        },
        submitButton: 'Sign in',
        submitButtonLoading: 'Signing in...',
        errors: {
          emailRequired: 'Email is required',
          emailInvalid: 'Invalid email',
          passwordMin: 'Minimum 6 characters',
          passwordRequired: 'Password is required'
        }
      },
      forgotPassword: 'Forgot your password?',
      noAccount: 'Don’t have an account yet?',
      createAccount: 'Create one now',
      chooseLanguage: 'Choose language',
      errors: {
        blockedUser:
          'User without access permission. Contact the administrator for more information.',
        expiredSubscription:
          'Subscription expired. Contact the administrator for more information.',
        expiredSession: 'Session expired. Please sign in again.',
        loginError: 'Invalid username or password. Please try again.'
      }
    },
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description: 'The next generation of candidate relationship management'
    },
    welcome: 'Welcome to',
    firstParagraph: 'A simple, easy, and smart recruitment platform.',
    secondParagraph: 'Enjoy the experience!',
    loginTitle: 'Sign in to your account',
    firstname: 'First Name',
    lastname: 'Last Name',
    email: 'Email',
    password: 'Password',
    loginCheckBox: 'Keep me signed in',
    forgotPassword: 'Forgot Password?',
    loginAccount: 'Sign in to your account!',
    registerTitle: 'Create your account!',
    repeatPassword: 'Repeat Password',
    registerCheckBox: 'I agree to the terms of use!',
    registerNow: 'Register Now!',
    aboutUs: 'About Us',
    privacy: 'Privacy Policies',
    terms: 'Terms',
    whatsRecrud: 'What is Recrud?',
    loginError: 'Invalid username or password. Please try again',
    expiredSession: 'Session expired. Please sign in again',
    blockedUser:
      'User without access permission. Contact the administrator for more information.',
    expiredSubscription:
      'Subscription expired. Contact the administrator for more information.',
    signupSuccess: 'Registration successful!',
    registerError: 'An error occurred while registering, please try again',
    registerErrorDataExists: 'The email is already in use, please try again',
    register: 'Register',
    login: 'Sign in',
    passwordConfirm: 'Passwords do not match!',
    language: 'Language',
    acquisitionChannel: 'How did you find us?'
  },
  card: {
    lugarh: {
      title:
        'Talk to a specialist and discover the best plans for your company',
      subtitle:
        'To find the plans that best meet your needs, we invite you to talk to our specialists, who are ready to assist you in a personalized way.',
      ctaButton: 'Explore plans',
      backButton: 'Back'
    }
  },
  selectableList: {
    lugarh: {
      searchInputPlaceholder: 'Search by name',
      selectAllLabel: 'Select all',
      notFound: 'No results found for the search'
    }
  },
  workspaceForm: {
    lugarh: {
      fetchUsersError: 'Error fetching users',
      placeholder: {
        name: 'Workspace Name',
        slug: 'Workspace Nickname',
        cnpj: 'CNPJ',
        postcode: 'ZIP Code',
        address_line1: 'Address (Street and Number)',
        address_line2: 'Complement',
        city: 'City',
        region: 'State',
        country: 'Country'
      },
      addAddressLabel: 'Add Address',
      submitButton: {
        stepOne: 'Continue',
        stepTwo: 'Submit',
        loading: 'Processing...'
      },
      errors: {
        'Nome do workspace deve ter no máximo 80 caracteres':
          'Workspace name must be at most 80 characters long',
        'Nome do workspace é obrigatório': 'Workspace name is required',
        'CNPJ deve conter apenas números': 'CNPJ must contain only numbers',
        'CNPJ deve ter 14 dígitos': 'CNPJ must have 14 digits',
        'CEP é obrigatório': 'ZIP Code is required',
        'Endereço é obrigatório': 'Address is required',
        'Cidade é obrigatória': 'City is required',
        'País é obrigatório': 'Country is required',
        'Adicione pelo menos um usuário': 'Add at least one user',
        'É obrigatório adicionar usuários': 'Adding users is mandatory'
      }
    }
  },
  updateWorkspaceForm: {
    lugarh: {
      fetchUsersError: 'Error fetching users',
      placeholder: {
        name: 'Workspace Name *',
        slug: 'Workspace Nickname (Optional)',
        cnpj: 'CNPJ (Optional)',
        postcode: 'ZIP Code',
        address_line1: 'Address (Street and Number)',
        address_line2: 'Complement',
        city: 'City',
        region: 'State',
        country: 'Country'
      },
      addAddressLabel: 'Add address to workspace',
      submitButton: {
        stepOne: 'Continue',
        stepTwo: 'Save information',
        loading: 'Processing...',
        back: 'Back'
      },
      errors: {
        'Nome do workspace deve ter no máximo 80 caracteres':
          'Workspace name must be at most 80 characters long',
        'Nome do workspace é obrigatório': 'Workspace name is required',
        'CNPJ deve conter apenas números': 'CNPJ must contain only numbers',
        'CNPJ deve ter 14 dígitos': 'CNPJ must have 14 digits',
        'CEP é obrigatório': 'ZIP Code is required',
        'Endereço é obrigatório': 'Address is required',
        'Cidade é obrigatória': 'City is required',
        'País é obrigatório': 'Country is required',
        'Adicione pelo menos um usuário': 'Add at least one user',
        'É obrigatório adicionar usuários': 'Adding users is mandatory'
      }
    }
  },
  editUserForm: {
    lugarh: {
      fetchWorkspacesError: 'Error fetching workspaces',
      placeholder: {
        firstname: 'First Name',
        lastname: 'Last Name',
        password: 'Password',
        email: 'Email',
        role: {
          select: 'Select role',
          admin: 'Administrator',
          manager: 'Manager',
          recruiter: 'Recruiter'
        }
      },
      submitButton: {
        stepOne: 'Continue',
        stepTwo: 'Submit',
        loading: 'Processing...',
        back: 'Back'
      },
      errors: {
        'Required field': 'Required field',
        'Nome do workspace deve ter no máximo 80 caracteres':
          'Workspace name must be at most 80 characters long',
        'Nome do workspace é obrigatório': 'Workspace name is required',
        'CNPJ deve conter apenas números': 'CNPJ must contain only numbers',
        'CNPJ deve ter 14 dígitos': 'CNPJ must have 14 digits',
        'CEP é obrigatório': 'ZIP Code is required',
        'Endereço é obrigatório': 'Address is required',
        'Cidade é obrigatória': 'City is required',
        'País é obrigatório': 'Country is required',
        'Adicione pelo menos um usuário': 'Add at least one user',
        'É obrigatório adicionar usuários': 'Adding users is mandatory'
      }
    },
    errors: {
      'Required field': 'Required field',
      'Password must have at least 6 characters': 'Minimum 6 characters',
      'Select at least one workspace': 'Select at least one workspace',
      'Workspace is a required field': 'Workspace is a required field'
    }
  },
  createWorkspaceModal: {
    lugarh: {
      title: 'Add Workspace',
      subtitle: 'Enter the information below to proceed',
      stepOne: '1. Basic Information',
      stepTwo: '2. Users'
    }
  },
  deleteWorkspaceModal: {
    lugarh: {
      title: 'Delete Workspace',
      subtitle: 'This action is permanent, are you sure you want to delete?',
      back: 'Cancel',
      delete: 'I want to delete',
      requestMessage: {
        '204': 'Workspace successfully deleted.',
        '401': 'Authentication failure or invalid credentials.',
        '403':
          'The workspace cannot be deleted because it is linked to one or more jobs.',
        '404': 'Workspace not found.',
        '500': 'Internal server error.'
      },
      loading: 'Processing...'
    }
  },
  updateWorkspaceModal: {
    lugarh: {
      title: 'Edit Workspace',
      subtitle: 'Change the information below to proceed',
      stepOne: '1. Basic Information',
      stepTwo: '2. Users',
      requestMessage: {
        '200': 'Workspace successfully updated.',
        '401': 'Authentication failure or invalid credentials.',
        '403': 'The workspace cannot be updated.',
        '404': 'Workspace not found.',
        '500': 'Internal server error.'
      }
    }
  },
  duplicateWorkspaceModal: {
    title: 'Duplicate to Workspace',
    button: 'Duplicate to Workspace'
  },
  createUserModal: {
    lugarh: {
      title: 'Add User',
      subtitle: 'Enter the information below to proceed',
      stepOne: '1. User Information',
      stepTwo: '2. Select Workspaces'
    }
  },
  editUserModal: {
    lugarh: {
      title: 'Edit User',
      subtitle: 'Enter the information below to proceed',
      stepOne: '1. User Information',
      stepTwo: '2. Select Workspaces'
    }
  },
  changeEnvironmentModal: {
    lugarh: {
      title: 'Workspaces',
      subtitle: 'Choose a workspace to work in',
      actions: {
        change: 'Change Workspace',
        manage: 'Manage Workspaces'
      }
    }
  },
  singleSelectableList: {
    lugarh: {
      placeholder: {
        search: 'Search by name'
      },
      searchNotFound: 'No items found'
    },
    recrud: {
      placeholder: {
        search: 'Search by name'
      },
      searchNotFound: 'No items found'
    }
  },
  moveWorkspaceModal: {
    lugarh: {
      modalTitle: 'Move job to another Workspace',
      modalSubtitle: 'Select the workspace to move the job to',
      submitButton: 'Move Job'
    }
  },
  resetPassword: {
    lugarh: {
      form: {
        title: 'Reset Password',
        password: {
          show: 'Show',
          hide: 'Hide'
        },
        newPassword: {
          placeholder: 'New Password'
        },
        confirmNewPassword: {
          placeholder: 'Confirm New Password'
        },
        submitButton: 'Reset',
        errors: {
          newPasswordMin: 'Minimum 6 characters',
          newPasswordRequired: 'New password is required',
          confirmNewPasswordRequired: 'Password confirmation is required',
          confirmNewPasswordMatch: 'Passwords do not match'
        },
        goToLogin: 'Back to login screen'
      },
      resetPasswordFail: 'Failed to reset the password.',
      resetPasswordSuccess: 'Password successfully reset.'
    }
  },
  forgotPassword: {
    lugarh: {
      form: {
        title: 'Forgot My Password',
        email: {
          placeholder: 'Email'
        },
        submitButton: 'Recover Password',
        errors: {
          emailRequired: 'Email is required',
          emailInvalid: 'Invalid email'
        },
        goToLogin: 'Back to login screen'
      }
    },
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description: 'The next generation of candidate relationship management'
    },
    welcome: 'Welcome to',
    firstParagraph: 'Manage your company’s recruitment processes',
    secondParagraph: 'simply and smartly!',
    forgotPassword: 'Forgot My Password',
    cardParagraph: 'Enter your email to receive password reset instructions',
    recoveryPassword: 'Recover Password',
    emailValidationError: 'Please enter a valid email',
    emailNotFound: 'Email not registered, please try again',
    sendEmailFail: 'Failed to send recovery email',
    sendEmailSuccess: 'Recovery email sent successfully'
  },
  plans: {
    metatags: {
      title: 'string',
      description: 'string',
      url: 'string'
    },
    selectPlanTitle: 'Select a Plan',
    plansCardBasic: {
      title: 'Basic',
      subTitle: 'For companies with few jobs',
      planValue: 'R$29,90',
      planPeriod: '/monthly',
      feat1: 'Up to 2 jobs per month',
      feat2: 'Unlimited users',
      feat3: 'Unlimited candidates'
    },
    plansCardSmart: {
      title: 'Smart',
      subTitle: 'For companies with moderate job volume',
      planValue: 'R$49,90',
      planPeriod: '/monthly',
      feat1: 'Up to 5 jobs per month',
      feat2: 'Unlimited users',
      feat3: 'Unlimited candidates'
    },
    plansCardAdvanced: {
      title: 'Advanced',
      subTitle: 'For companies with high job volume',
      planValue: 'R$69,90',
      planPeriod: '/monthly',
      feat1: 'Up to 10 jobs per month',
      feat2: 'Unlimited users',
      feat3: 'Unlimited candidates'
    },
    plansCardEnterprise: {
      title: 'Enterprise',
      subTitle: 'For large job volumes',
      planValue: 'Customized Quote',
      planPeriod: '',
      feat1: 'More than 10 jobs per month',
      feat2: 'Unlimited users',
      feat3: 'Unlimited candidates',
      selectButton: 'Talk to an Expert'
    },
    selectButton: 'Get Started'
  },
  checkoutPlan: {
    metatags: {
      title: 'string',
      description: 'string',
      url: 'string'
    },
    companyFormTitle: 'Company Details',
    paymentFormTitle: 'Payment Method',
    creditCardLabel: 'Credit Card',
    cardNumber: 'Card Number',
    cvv: 'CVV',
    expirationMonth: 'Expiration Month',
    expirationYear: 'Expiration Year',
    cardHolderName: 'Cardholder Name',
    changePlanButton: 'Change Plan',
    paymentButton: 'Subscribe',
    cardTokenizationError: 'Check the credit card details',
    iugoCardTokenizationFailure:
      'An internal error occurred while processing the card',
    billingAddressFromTitle: 'Billing Address',
    phone: 'Phone',
    cpfCnpj: 'CPF/CNPJ',
    zipcode: 'ZIP Code',
    district: 'District',
    street: 'Street',
    number: 'Number',
    complement: 'Complement',
    city: 'City',
    state: 'State'
  },
  myProfile: {
    banner: {
      title: 'Edit Profile',
      text: 'Edit your personal information, passwords, and more!'
    },
    form: {
      firstname: 'First Name',
      lastname: 'Last Name',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      save: 'Save Changes!',
      successEditMessage: 'Changes saved successfully!'
    },
    confirmationModal: {
      wrongPassword: 'Incorrect password',
      failToSend: 'Failed to send email',
      passwordConfirmationSucess:
        'Password confirmed. A code has been sent to your new email',
      unavailable: 'Cannot perform the operation at this moment',
      wrongCode: 'Error validating the code'
    }
  },
  candidate: {
    lugarh: {
      sectionTab: {
        notDefined: 'Oops! No option has been selected in the side menu.'
      },
      basicInfo: {
        createdAt: 'Registration',
        updatedAt: 'Last update',
        years: 'years',
        missingData: {
          title: 'Title not provided',
          location: 'Location not provided',
          age: 'Birth date not provided',
          salaryExpectation: 'Salary not provided',
          email: 'Email not provided',
          phone: 'Phone not provided',
          linkedin: 'LinkedIn not provided'
        },
        copyToClipboard: {
          success: 'Copied successfully!',
          error: 'Error copying. Information not provided by the candidate.',
          errorWhileCopying: 'Error copying.'
        }
      },
      asideSectionNav: {
        curriculum: 'CV',
        history: 'History',
        export: 'Export',
        apply: 'Apply for Job'
      }
    }
  },
  addCandidate: {
    lugarh: {
      title: 'Add Candidate',
      subtitle:
        'This is your general dashboard for your company’s recruitment process',
      submitFormButton: 'SAVE INFORMATION',
      personalDataBox: {
        title: 'Personal Data',
        firstname: 'First Name *',
        lastname: 'Last Name *',
        birthdate: 'Date of Birth',
        maritalStatus: 'Marital Status',
        dateFormat: 'MM/DD/YYYY',
        maritalStatusOptions: {
          select: 'Select',
          divorced: 'Divorced',
          married: 'Married',
          single: 'Single',
          widowed: 'Widowed',
          other: 'Other'
        },
        sex: 'Gender',
        sexOptions: {
          female: 'Female',
          male: 'Male',
          other: 'Other'
        },
        phone: 'Mobile Phone',
        email: 'Email',
        geoLocation: 'Location *',
        cityRelocation: 'Available for relocation?',
        cityRelocationOptions: {
          yes: 'Yes',
          no: 'No'
        },
        salary_expectation: 'Salary Expectation',
        linkedin_profile: 'LinkedIn',
        facebook_profile: 'Facebook',
        twitter_profile: 'Twitter',
        origin: 'Candidate Origin',
        about: 'About *'
      },
      languageBox: {
        title: 'Language',
        language: 'Language *',
        level: 'Level *',
        levelOptions: {
          basic: 'Basic',
          intermediate: 'Intermediate',
          fluent: 'Fluent',
          native: 'Native'
        },
        button: 'Add New Language',
        deleteButton: 'Delete'
      },
      educationBox: {
        title: 'Education',
        course: 'Course *',
        institution: 'Institution',
        startDate: 'Start Date *',
        endDate: 'End Date *',
        level: 'Level *',
        dateFormat: 'MM/DD/YYYY',
        levelOptions: {
          incomplete_middleschool: 'Incomplete Middle School',
          middleschool: 'Completed Middle School',
          incomplete_highschool: 'Incomplete High School',
          highschool: 'Completed High School',
          undergraduate: 'Undergraduate',
          postgraduate: 'Postgraduate',
          masters: 'Master’s',
          doctorate: 'Doctorate'
        },
        description: 'About',
        button: 'Add New Course',
        deleteButton: 'Delete'
      },
      certificateBox: {
        title: 'Certificates and Achievements',
        name: 'Name *',
        hours: 'Hours *',
        button: 'Add New Achievement',
        deleteButton: 'Delete'
      },
      experienceBox: {
        title: 'Professional Experience',
        role: 'Role *',
        company: 'Company',
        startDate: 'Start Date *',
        endDate: 'End Date',
        description: 'Activity Description',
        currentJob: 'I am currently working at this company *',
        button: 'Add New Experience',
        deleteButton: 'Delete',
        dateFormat: 'MM/DD/YYYY'
      },
      otherInformationBox: {
        title: 'Other Information',
        other_info: 'Other Information'
      },
      attachmentsBox: {
        title: 'File Upload',
        button: 'Add File',
        attachmentError:
          'Only PDF, PNG, JPEG, and JPG files up to 3MB are allowed.',
        underlineText: 'Click to upload',
        description: 'or drag and drop the file here',
        fileTypes: 'PDF, PNG or JPG (max. 3MB)',
        success: 'Completed',
        uploadFailed: 'Upload failed',
        largeDocumentError: 'Document too large  •  Failed'
      },
      messages: {
        success: 'Candidate added successfully!'
      },
      submitButton: 'Submit'
    },
    button: {
      addAttachments: 'Add File'
    },
    banner: {
      title: 'Add Candidate',
      text: 'Add candidate to the database'
    },
    form: {
      email: 'Email',
      firstname: 'First Name',
      lastname: 'Last Name',
      about: 'About',
      geoLocation: 'Location',
      phone: 'Phone',
      maritalStatus: 'Marital Status',
      sex: 'Gender',
      birthdate: 'Date of Birth',
      salaryExpectation: 'Salary Expectation',
      cityRelocation: 'Available for relocation?',
      linkedinProfile: 'LinkedIn',
      facebookProfile: 'Facebook',
      twitterProfile: 'Twitter',
      save: 'Save Changes!',
      successMessage: 'Changes saved successfully!'
    },
    titleBox: {
      personalData: 'Personal Data',
      language: 'Language',
      education: 'Education',
      certificatesAndAchievements: 'Certificates and Achievements',
      experience: 'Professional Experience',
      otherInformation: 'Other Information',
      attachments: 'File Upload'
    },
    languageBox: {
      language: 'Language',
      level: 'Level'
    },
    educationBox: {
      course: 'Course',
      institution: 'Institution',
      startDate: 'Start Date',
      endDate: 'End Date',
      level: 'Level',
      about: 'About'
    },
    certificateBox: {
      name: 'Name',
      hours: 'Hours'
    },
    experienceBox: {
      role: 'Role',
      company: 'Company',
      startDate: 'Start Date',
      endDate: 'End Date',
      currentJob: 'Current Job',
      description: 'Activity Description'
    },
    otherInformation: 'Other Information',
    attachmentSubTitleDescription:
      'Please attach supporting documents. [E.g.: Resume, CV, and/or Cover Letter]',
    attachmentSubTitleFileDescription: 'Attachments',
    attachmentError:
      'Only PDF, PNG, JPEG, and JPG files up to 5MB are allowed.',
    sexOptions: {
      female: 'Female',
      male: 'Male',
      other: 'Other'
    },
    maritalStatusOptions: {
      select: 'Select',
      divorced: 'Divorced',
      married: 'Married',
      single: 'Single',
      widowed: 'Widowed',
      other: 'Other'
    },
    cityRelocationOptions: {
      yes: 'Yes',
      no: 'No'
    }
  },
  candidateDetail: {
    movedTo: 'moved to stage {{stageName}} by {{authorName}}.',
    appliedTo: 'applied to position.',
    current: 'Current'
  },
  companySettings: {
    save: 'Save Changes!',
    banner: {
      title: 'Company Settings',
      text: 'Edit information about the company, talent page, sensitive data, and more!'
    },
    color: {
      companyPageHeaderColor: 'Career Page Header Color',
      companyPageFontColor: 'Career Page Font Color',
      companyPageButtonColor: 'Career Page Button Color',
      companyPageButtonFontColor: 'Career Page Button Font Color'
    },
    careerPageSetting: {
      title: 'Career Page Settings',
      showHomeButtonTitle: 'Show Home Button',
      showHomeButtonText: 'Decide if the candidate will see the home button.',
      showMainPageTitle: 'Show Main Page',
      showMainPageText:
        'Decide if the candidate will be able to access the main page.',
      careerPageLabel: 'Enable?'
    },
    lgpd: {
      title: 'Personal Data / Sensitive Data / LGPD',
      label: {
        question: 'Enable?',
        required: 'Required?'
      },
      askPhoneTitle: 'Ask for Phone Number',
      askPhoneText:
        'Decide if the candidate will provide their phone number and if you will process this information.',
      civilStatusTitle: 'Marital Status',
      civilStatusText:
        'Decide if the candidate will provide their marital status and if you will process this information.',
      askGenderTitle: 'Ask for Gender',
      askGenderText:
        'Decide if the candidate will provide their gender and if you will process this information.',
      askBirthdateTitle: 'Ask for Date of Birth',
      askBirthdateText:
        'Decide if the candidate will provide their date of birth and if you will process this information.',
      askWagePretensionTitle: 'Ask for Salary Expectation',
      askWagePretensionText:
        'Decide if the candidate will provide their salary expectation and if you will process this information.',
      askMovingAvailabilityTitle: 'Ask for Relocation Availability',
      askMovingAvailabilityText:
        'Decide if the candidate will provide their relocation availability and if you will process this information.',
      askAboutLinkedinProfileTitle: 'Ask about LinkedIn Profile',
      askAboutLinkedinProfileText:
        'Decide if the candidate will provide their LinkedIn profile and if you will process this information.',
      askAboutFacebookProfileTitle: 'Ask about Facebook Profile',
      askAboutFacebookProfileText:
        'Decide if the candidate will provide their Facebook profile and if you will process this information.',
      askAboutTwitterProfileTitle: 'Ask about Twitter Profile',
      askAboutTwitterProfileText:
        'Decide if the candidate will provide their Twitter profile and if you will process this information.',
      termsOfUseTitle: 'Terms of Use',
      termsOfUseText: 'Insert a link to the terms of use.'
    },
    companyForm: {
      name: 'Company Name',
      fancyName: 'Trade Name',
      cnpj: 'CNPJ',
      description: 'Company Description',
      location: 'Location',
      site: 'Link to Career Site'
    },
    changesSaved: 'Changes Saved!',
    messageChangesSaved:
      'Your company page has been updated. You can check it by accessing the link:'
  },
  onboarding: {
    buttons: {
      next: 'Next',
      previous: 'Previous',
      skip: 'Skip'
    },
    dashboard: {
      finishOnboardingButton: 'Finish Initial Tour',
      content: [
        {
          title: 'Welcome to Recrud',
          text: 'Since this is your first time here, we will guide you through this simple tutorial to help you get to know Recrud better and explore it effectively.'
        },
        {
          title: 'The Navigation Bar',
          text: 'This is the system’s navigation bar. From here, you can access your jobs, candidate database, and return to the dashboard.'
        },
        {
          title: 'The Search Bar',
          text: 'If you want to find specific jobs or candidates, you can search here to save time.'
        },
        {
          title: 'The Admin Panel',
          text: 'If you are a manager, you can configure your company’s page, manage your hiring workflow, and add or remove team members here.<br /><br />If you are a recruiter, you can edit your profile and change your password here.'
        },
        {
          title: 'The Dashboard',
          text: 'Track your hiring process data through the dashboard, such as average hiring time, candidate base numbers, and more.'
        },
        {
          title: 'Recrud',
          text: 'Now you are familiar with the initial part of the system. Navigate to other pages to continue the tutorial.'
        }
      ]
    },
    jobs: {
      finishOnboardingButton: 'Finish Job Screen Tour',
      content: [
        {
          title: 'Jobs',
          text: 'This is the job panel, where all active job postings from your company are displayed so that candidates can apply. Let’s explore more details about this page.'
        },
        {
          title: 'Create a New Job',
          text: 'Need to create a new job? To register it, click the indicated button and fill out the registration form!'
        },
        {
          title: 'Job Status',
          text: 'A job can be active or inactive. If the bar is green, the job is active. If it is red, it is inactive. Candidates can only apply to active jobs.'
        },
        {
          title: 'Recruiters',
          text: 'You can add or remove recruiters responsible for this job by clicking “Edit”'
        },
        {
          title: 'Candidates',
          text: 'Here you can manage and view the candidates who have applied for this job.'
        },
        {
          title: 'Share Jobs',
          text: 'In the “Share” button, you will find available links to share your job on each social network.'
        },
        {
          title: 'Edit Job',
          text: 'In the “Edit” button, you can change the job information and also the responsible recruiters.'
        },
        {
          title: 'Filters',
          text: 'If you have many jobs and want to find them quickly, use the filter panel.'
        },
        {
          title: 'List or Card View',
          text: 'Want to view your jobs in another format? Choose here if you prefer to see them in cards or in a list format.'
        }
      ]
    },
    cadidatesDB: {
      finishOnboardingButton: 'Finish Candidate Database Tour!',
      content: [
        {
          title: 'Candidates',
          text: 'This is your talent pool. All candidates who applied for your jobs will be here!'
        },
        {
          title: 'Sort Candidates',
          text: 'If you want to sort your candidates to make your search easier, check the sorting options here.'
        },
        {
          title: 'Filter Candidates',
          text: 'Too many candidates? You can use the side panel to filter by specific fields related to education, experience, and other criteria.'
        },
        {
          title: 'Resume Preview',
          text: 'You don’t need to access each candidate’s page individually. Click here to view a summary of the information and make your assessment easier.'
        },
        {
          title: 'Candidate Details',
          text: 'If the preview isn’t enough, you can access all information about this candidate by clicking here to view the full resume and history.'
        },
        {
          title: 'Apply to a Job',
          text: 'If you want to add this candidate to a job, you can do it here.'
        }
      ]
    },
    candidateDetail: {
      finishOnboardingButton: 'Finish Tutorial!',
      content: [
        {
          title: 'Candidate Details',
          text: 'Here you find all detailed information about a candidate, including application history, notes made by recruiters, and much more.'
        },
        {
          title: 'History',
          text: 'In the candidate’s history, you can review all stages the candidate has passed in the hiring process.'
        },
        {
          title: 'Apply to the Job',
          text: 'If you have permission to manage this process, you can move the candidate to other stages.'
        },
        {
          title: 'Recruiter Rating',
          text: 'You can check the rating given by a recruiter. Ratings are important information during the candidate’s process.'
        },
        {
          title: 'Rate Candidate',
          text: 'To rate the candidate, simply use the stars.'
        },
        {
          title: 'Notes',
          text: 'If you want to record information about the candidate, use the text box.'
        }
      ]
    },
    candidates: {
      finishOnboardingButton: 'Finish Tutorial!',
      content: [
        {
          title: 'Candidates',
          text: 'These are the candidates who applied for an available job. Here, you can manage them to follow the steps of your hiring workflow.'
        },
        {
          title: 'Hiring Workflow Stages',
          text: 'This bar displays all stages of the Hiring Workflow assigned to this job. Here, you can filter your candidates by stage. Click on a stage to see the candidates in it.'
        },
        {
          title: 'Sort Candidates',
          text: 'If you want to sort your candidates to make your search easier, check the sorting options here.'
        },
        {
          title: 'Filter Candidates',
          text: 'Too many candidates? You can use the side panel to filter by specific fields related to education, experience, and other criteria.'
        },
        {
          title: 'Resume Preview',
          text: 'You don’t need to access each candidate’s page individually. Click here to view a summary of the information and make your assessment easier.'
        },
        {
          title: 'Candidate Details',
          text: 'If the preview isn’t enough, you can access all information about this candidate by clicking here to view the full resume and history.'
        },
        {
          title: 'Move Candidate',
          text: 'If you already know which stage the candidate fits into, you can do it here.'
        }
      ]
    },
    companySettings: {
      finishOnboardingButton: 'Finish Tutorial!',
      content: [
        {
          title: 'Company Settings',
          text: 'Configure your careers page here. This page will be used to display your active jobs.'
        },
        {
          title: 'Main Data',
          text: 'Start by adding the main data. Don’t worry, only the company name, location, and description will be displayed on the careers page.'
        },
        {
          title: 'Company Logo',
          text: 'Add the company logo to compose the page header. We recommend it to be at least 300px wide and have a transparent background.'
        },
        {
          title: 'Configure with Company Colors',
          text: 'Set the color for the header, texts, and buttons on the careers page.'
        },
        {
          title: 'Sensitive Data',
          text: 'Choose which additional information you want to collect from candidates when they apply for your jobs.'
        }
      ]
    },
    companyTeam: {
      finishOnboardingButton: 'Finish Team Management Screen Tour!',
      content: [
        {
          title: 'Your Company Team',
          text: 'Here, you can manage your company’s users and set their permissions.'
        },
        {
          title: 'Users',
          text: 'Users can have access profiles such as administrators, managers, or recruiters. Only administrators have permission to edit company settings, modify hiring workflows, and manage users.'
        },
        {
          title: 'User Status',
          text: 'A user can be active or blocked, and this bar will indicate their status. If it is green, the user is active. If it is red, the user is blocked and cannot access the platform.'
        },
        {
          title: 'Block or Activate Users',
          text: 'To block or reactivate users, simply click here.'
        },
        {
          title: 'Edit User',
          text: 'Here you can edit a user included in the team. You can edit name, email, password, and permissions.'
        },
        {
          title: 'Delete User',
          text: 'To delete a user, click the trash can icon.'
        },
        {
          title: 'Register a New User',
          text: 'To add a new user, just click this button and fill out the form.'
        }
      ]
    },
    jobsEdit: {
      finishOnboardingButton: 'Finish Tutorial!',
      content: [
        {
          title: 'Job Registration/Editing',
          text: 'Configure your job information here. Add the main details to start.'
        },
        {
          title: 'Link to a Hiring Workflow',
          text: 'To have a defined step-by-step process for your job, create a Hiring Workflow in “Hiring Workflows” found in the gear icon at the top right of the screen, and then link it to this job here.'
        },
        {
          title: 'Custom Questions',
          text: 'You can create custom questions to be displayed to the candidate during the application. You can create single-choice, multiple-choice, and open-text questions. Single-choice questions can be marked as eliminatory, automatically moving the candidate to the “Proposal Rejected” stage if answered incorrectly.'
        },
        {
          title: 'Recruiters and Managers',
          text: 'Drag available recruiters or managers to be part of the hiring team for this job and be able to make decisions and take actions during the process.'
        },
        {
          title: 'Save',
          text: 'Save the changes made to finalize your job.'
        }
      ]
    },
    workflow: {
      finishOnboardingButton: 'Finish Tutorial!',
      content: [
        {
          title: 'Hiring Workflows',
          text: 'Here, you can create and manage hiring workflows for each job. This way, you will know exactly which stages your candidates will go through until hiring.'
        },
        {
          title: 'Edit/View Hiring Workflow',
          text: 'To edit or view a hiring workflow, just click here!'
        },
        {
          title: 'Delete Hiring Workflow',
          text: 'To delete a hiring workflow, click the trash can icon. A hiring workflow can only be deleted if it is not linked to any job.'
        },
        {
          title: 'Register a New Hiring Workflow',
          text: 'To register a new hiring workflow, just click this button!'
        }
      ],
      add: 'Add New Hiring Workflow',
      update: 'Update Hiring Workflow'
    },
    workflowEdit: {
      finishOnboardingButton: 'Finish Tutorial!',
      content: [
        {
          title: 'Hiring Workflow',
          text: 'Here you can manage the stages of your hiring workflow. The stages registered here will be available for you to move candidates during the selection process.'
        },
        {
          title: 'Default Stages',
          text: 'Recrud already has some default stages that cannot be deleted. The first stage is “Available”. Each candidate applying for a job starts in this stage. The other default stages are: Rejected in Screening, Approved in Screening, Proposal Rejected, and Hired.'
        },
        {
          title: 'Create Stage',
          text: 'In Recrud, you can structure the selection process in a way that makes sense for you. To add a new stage, simply click “Create New Stage Here”.'
        },
        {
          title: 'Custom Stages',
          text: 'Custom stages are identified by the orange color.'
        },
        {
          title: 'Delete Custom Stage',
          text: 'Custom stages can be deleted if the hiring workflow is not linked to a job.'
        },
        {
          title: 'Send Automatic Email',
          text: 'Enable this feature if you want the candidate to receive the configured email as soon as they are moved to the stage.'
        },
        {
          title: 'Automatic Email Content',
          text: 'The content of the automatic email can be changed by clicking this button. You can edit it as you prefer.'
        }
      ]
    }
  },
  jobCard: {
    candidates: 'Candidates',
    createNewVacancy: 'Create new vacancy',
    edit: 'Edit',
    move: 'Move',
    duplicate: 'Duplicate',
    share: 'Share',
    team: 'Team',
    noteam: 'No team selected',
    managers: 'Manager',
    recruiter: 'Recruiter',
    toggleJob: 'Pause/Resume vacancy',
    overdueJob: 'Overdue vacancy',
    closedJob: 'Closed vacancy',
    stopJob: 'Pause',
    startJob: 'Start'
  },
  teamManagement: {
    title: 'Manage Team',
    text: 'Create and edit users for your company',
    blockModal: {
      title: 'Confirmation',
      description: 'Are you sure to <strong>block</strong> the user?',
      undo: 'Cancel',
      confirm: 'Yes, block!',
      success: 'User blocked successfully!'
    },
    unblockModal: {
      title: 'Confirmation',
      description: 'Are you sure to <strong>unblock</strong> the user?',
      undo: 'Cancel',
      confirm: 'Yes, unblock!',
      success: 'User unblocked successfully!'
    },
    deleteModal: {
      title: 'Confirmation',
      description: 'Are you sure to <strong>delete</strong> the user ',
      undo: 'Cancel',
      confirm: 'Yes, remove!',
      success: 'User removed successfully!'
    },
    editModal: {
      success: 'User updated successfully!'
    },
    addModal: {
      success: 'User created successfully!'
    }
  },
  teamCard: {
    createNewUser: 'Create New User',
    edit: 'Edit',
    block: 'Block',
    reactivate: 'Reactivate',
    delete: 'Delete',
    profileOptions: {
      admin: 'Administrator',
      manager: 'Manager',
      recruiter: 'Recruiter'
    },
    modalWorkspacesTitle: 'Linked Workspaces',
    modalWorkspacesSubtitle: 'View the list of workspaces linked to this user.'
  },
  userModal: {
    edit: {
      title: 'Edit User',
      confirm: 'Edit User!'
    },
    add: {
      title: 'Add User',
      confirm: 'Create User!'
    },
    firstname: 'First Name',
    lastname: 'Last Name',
    email: 'Email',
    password: 'Password',
    profile: 'Profile',
    profileOptions: {
      admin: 'Administrator',
      manager: 'Manager',
      recruiter: 'Recruiter'
    },
    emailEdit: 'To change your email, access profile editing or',
    clickHere: 'click here'
  },
  hiringStages: {
    text: 'Click on a stage to view the candidates in that stage.'
  },
  jobHeader: {
    firstOption: 'Delayed Jobs',
    secondOption: 'Active',
    thirdOption: 'On Hold',
    fourthOption: 'All',
    searchLabel: 'Filter by Workspaces',
    selectLabel: 'Filter by'
  },
  candidateHeader: {
    lugarh: {
      title: 'General Candidate Database',
      subtitle: 'This is the general resume database for your company',
      filter: 'FILTER',
      addCandidate: 'ADD CANDIDATE',
      export: 'EXPORT',
      applyCandidates: 'APPLY FOR POSITION',
      selectCandidates: 'Select all',
      candidate: {
        apply: 'APPLY FOR POSITION',
        detail: 'VIEW RESUME',
        preview: 'PREVIEW'
      },
      candidateListHeaders: {
        name: 'NAME',
        resumes: 'RESUMES',
        apply: 'APPLY FOR POSITION'
      }
    },
    title: "Showing candidates for '{{opportunity}}' in stage '{{stage}}'",
    databaseTitle: 'Candidate Database',
    orderBy: 'Sort by',
    moveSelected: 'Move selected to stage',
    orderByOptions: {
      created_at: 'Date Added',
      name: 'Candidate Name',
      firstname: 'Candidate First Name',
      review: 'Average Rating',
      applicationDate: 'Application Date'
    }
  },
  candidateList: {
    select: 'Move to stage',
    review: 'Average Rating',
    preview: 'Preview',
    detail: 'Detail',
    apply: 'Apply for Position',
    add: 'Add',
    addCandidate: 'Add Candidate'
  },
  select: {
    acquisitionChannel: {
      0: 'Select'
    },
    vacancyAllocation: {
      0: 'Select',
      1: 'Hybrid',
      2: 'On-site',
      3: 'Remote',
      4: 'Not Specified'
    },
    education: {
      0: 'Incomplete Elementary',
      1: 'Elementary',
      2: 'Incomplete High School',
      3: 'High School',
      4: 'Higher Education',
      5: 'Postgraduate',
      6: "Master's",
      7: 'Doctorate'
    },
    language: {
      0: 'Basic',
      1: 'Intermediate',
      2: 'Fluent',
      3: 'Native'
    }
  },
  content: {
    whatsrecrud: {
      title: 'What is Recrud?',
      subtitle: 'Lorem ipsum'
    },
    privacypolicy: {
      title: 'Privacy Policies',
      subtitle: 'Lorem ipsum'
    },
    terms: {
      title: 'Terms',
      subtitle: 'Lorem ipsum'
    }
  },
  candidatePreview: {
    about: 'About',
    education: 'Education',
    experience: 'Experience',
    languages: 'Languages',
    closePreview: 'Close Preview',
    educationIn: 'in',
    current: 'current',
    basic: 'Basic',
    intermediate: 'Intermediate',
    fluent: 'Fluent',
    native: 'Native',
    single: 'Single',
    married: 'Married',
    divorced: 'Divorced',
    widowed: 'Widowed',
    other: 'Other',
    male: 'Male',
    female: 'Female'
  },
  languageLevelOptions: {
    basic: 'Basic',
    intermediate: 'Intermediate',
    fluent: 'Fluent',
    native: 'Native'
  },
  candidateFilter: {
    modalTitle: 'Filter Candidates',
    getDataError:
      'There was a problem retrieving some information. Please try reloading the page.',
    genderOptions: {
      other: 'Other',
      male: 'Male',
      female: 'Female'
    },
    civilStatusOptions: {
      single: 'Single',
      married: 'Married',
      divorced: 'Divorced',
      widowed: 'Widowed',
      other: 'Other'
    },
    availableToMoveOptions: {
      true: 'Yes',
      false: 'No'
    },
    languageLevelOptions: {
      basic: 'Basic',
      intermediate: 'Intermediate',
      fluent: 'Fluent',
      native: 'Native'
    },
    educationLevelOptions: {
      incomplete_middleschool: 'Incomplete Elementary School',
      middleschool: 'Completed Elementary School',
      incomplete_highschool: 'Incomplete High School',
      highschool: 'Completed High School',
      undergraduate: 'Undergraduate',
      postgraduate: 'Postgraduate',
      masters: 'Master’s Degree',
      doctorate: 'Doctorate'
    },
    buttons: {
      filter: 'Filter',
      filterCandidate: 'Filter Candidates',
      applyFilters: 'Apply Filters'
    },
    general: {
      title: 'General',
      clearFilters: 'Clear Filters',
      location: 'Location',
      age: {
        min: 'Minimum Age',
        max: 'Maximum Age'
      },
      gender: 'Gender',
      civilStatus: 'Civil Status',
      salary: {
        min: 'Minimum Salary',
        max: 'Maximum Salary'
      },
      availableToMove: 'Available to Relocate?',
      workspacesMltiselect: 'Workspace(s)'
    },
    education: {
      title: 'Education',
      name: 'Institution',
      course: 'Course',
      level: 'Level'
    },
    experience: {
      title: 'Experience',
      company: 'Company',
      position: 'Position',
      timeInYears: 'Years of Experience'
    },
    language: {
      title: 'Language',
      name: 'Language',
      level: 'Level'
    }
  },
  aboutContainer: {
    goBack: 'Go Back',
    about: 'About Me',
    education: 'Education',
    birthdate: 'Date of Birth',
    experience: 'Professional Experience',
    otherInfo: 'Other Information',
    languages: 'Languages',
    certificates: 'Certificates and Achievements',
    certificateHours: '{{hours}} hours',
    personalInfo: 'Personal Information',
    yes: 'Yes',
    not: 'No',
    email: 'Email',
    phone: 'Phone',
    localization: 'Location',
    maritalStatus: 'Marital Status',
    gender: 'Gender',
    salaryExpectation: 'Salary Expectation',
    possibilityChange: 'Willing to Relocate',
    attachedFiles: 'Attached Files'
  },
  workflowCard: {
    create: 'Create New Hiring Workflow',
    edit: 'Rename',
    view: 'Edit',
    noWorkspaces: 'No Workspaces',
    tooltip:
      'You cannot delete this workflow as there are job postings associated with it.',
    modalWorkspacesTitle: 'Linked Workspaces',
    modalWorkspacesSubtitle:
      'View the list of workspaces linked to this workflow'
  },
  jobOpportunity: {
    addJobTitle: 'Add Job',
    addJobText: 'Complete the form below to add a new job',
    editJobTitle: 'Edit Job',
    editJobText: 'Edit job for ',
    name: 'Job Title',
    roleFamily: 'Related Roles',
    roleFamilyPlaceholder: 'Related Roles (Type to see options)',
    localization: 'Job Location',
    amount: 'Number of Openings',
    deadline: 'Application Deadline',
    vacancy_allocation: 'Job Allocation',
    budget: 'Job Budget',
    tags: 'Job Tags',
    description: 'Job Description',
    applyJobOpportunity: 'Apply for Job',
    applyCandidate: 'Apply Candidate!',
    jobNameLabel: 'Job Title',
    applyToStep: 'Apply at Step',
    success: 'Candidate successfully applied for the job!',
    chooseOne: 'Choose an option',
    noJobFound: 'No job found'
  },
  recruiterNotes: {
    newNote: 'Enter additional details here...',
    notes: 'Notes',
    note: 'Note',
    new: 'New',
    by: 'by {{name}}',
    review:
      'Provide your rating for this candidate for the position of {{jobName}}',
    notAllowed: 'not Allowed'
  },
  historyContainer: {
    title: 'History',
    description: 'of candidate applications',
    appliedForJob: 'applied for the position',
    onDate: 'on {{date}}',
    onWorkspace: 'in the workspace'
  },
  recruiterAnswers: {
    title: 'Responses to Custom Job Questions'
  },
  job: {
    banner: {
      title: 'Register Position',
      text: ''
    },
    bannerEdit: {
      title: 'Edit Position',
      text: 'Edit Position of '
    },
    updatedMessage: 'Successfully updated!',
    form: {
      name: 'Position Name',
      location: 'Position Location',
      deadline: 'Application Deadline',
      vacancy_allocation: 'Vacancy Allocation',
      tags: 'Position Tags',
      roleFamilyPlaceholder: 'Related Roles (Type to see options)',
      amount: 'Number of Positions',
      budget: 'Position Budget',
      status: 'Activate Position?',
      description: 'Position Description'
    },
    linkWorkflow: 'Link to a Hiring Workflow',
    questions: 'Custom Questions for Candidates',
    questionsInfo:
      'There are already candidates who have applied for this position, so the questions cannot be changed.',
    recruiters: 'Available Recruiters',
    recruitersTeam: 'Team Recruiters',
    managers: 'Available Managers',
    managersTeam: 'Team Managers',
    save: 'Save Changes!',
    creationFailed:
      'Failed to create the position. Please complete the required fields and try again.',
    creationSuccess: 'Created successfully',
    missingWorkflow:
      'Failed to create the position. Please select a Hiring Workflow and try again.',
    updateStatusfalse: 'The position has been successfully paused!',
    updateStatustrue: 'The position has been successfully resumed!',
    modalTitle: 'Confirmation',
    modalTextfalse: 'Do you wish to pause the position ',
    modalTexttrue: 'Do you wish to resume the position',
    yes: 'Yes',
    no: 'No',
    tagError: 'Reached the maximum limit of 34 characters.'
  },
  jobShare: {
    shareManually: 'Share manually',
    shareWith: 'Share on {{socialMedia}}',
    message:
      '<p><copy>Click here</copy> to copy the sharing link. Then, paste it where you wish to share.</p>',
    url: 'Or use this address: ',
    onCopyingSuccessfully: 'Link copied successfully.'
  },
  workflowStepCard: {
    create: 'Create a new step here'
  },
  hiringWorkflow: {
    bannerTitle: 'Hiring Workflows',
    bannerText: 'Manage your company’s hiring workflows',
    addNewStage: 'Add New Stage',
    stageName: 'Stage Name',
    stageDescription: 'Stage Description',
    sendEmailAutomatically: 'Send Email Automatically?',
    stageEmailContent: 'Email Content',
    createHiringStage: 'Create Hiring Stage',
    emailSubject: 'Email Subject'
  },
  listWorkflowsContainer: {
    modalTitle: 'Confirmation',
    modalText:
      'Do you really want to <strong>delete</strong> the Hiring Workflow?',
    successDeleteMessage: 'Hiring Workflow successfully removed!',
    successCreateMessage: 'Hiring Workflow successfully created!',
    successUpdateMessage: 'Hiring Workflow successfully updated!',
    successHiringStageCreateMessage: 'Stage successfully created!',
    successDuplicateMessage: 'Hiring Workflow successfully duplicated!',
    yes: 'Yes',
    no: 'No',
    lockedDeletion:
      'Cannot delete the Hiring Workflow as it is associated with a job posting',
    copy: 'copy'
  },
  hiringWorkflowEdit: {
    bannerTitle: 'Hiring Workflow for',
    bannerText: 'Edit the Hiring Workflow',
    firstP:
      'Register the selection process workflow for your job here. You can associate this workflow with a job posting, and the stages can be used to filter and organize candidates. Remember, with the hiring workflow, you will know the stage each candidate is in during the process. If you need assistance with the registration, ',
    button: 'click here'
  },
  editWorkdflowContainer: {
    blocked: 'Default stage, cannot be removed',
    editEmail: 'Edit Email',
    stage: 'HIRING WORKFLOW STAGE',
    sendEmail: 'SEND AUTOMATIC EMAIL?',
    emailContent: 'EMAIL CONTENT',
    action: 'ACTION',
    yes: 'Yes',
    no: 'No',
    confirm: 'Confirmation',
    deleteContent: 'Do you really want to <strong>delete</strong> the stage?',
    successDeleteMessage: 'Stage successfully removed!',
    editEmailModalTitle: 'Edit Stage Email',
    editEmailModalSubTitle:
      'This email will be sent when the candidate is added to the',
    editEmailModalSubject: 'Subject',
    editEmailModalContent: 'Email Body',
    createStageTitle: '+ Add New Stage',
    createStageName: 'Stage Name',
    createStageDescription: 'Stage Description',
    createStageSendEmail: 'Send Email Automatically?',
    createStageEmailContent: 'Email Content',
    createStageButton: 'Create Hiring Stage!',
    successHiringStageCreateMessage: 'Stage successfully created!',
    successUpdateAutoSend: 'Automatic email sending updated successfully!',
    successUpdateEmailContent: 'Email content updated successfully!',
    save: 'Save Changes',
    remove: 'Remove',
    emailSubject: 'Email Subject',
    headerTitle: 'Hiring Workflow'
  },
  notFound: {
    button: 'Back',
    content:
      'It seems you took a wrong turn and stumbled upon a black hole on the web that swallowed the page you were looking for! But don’t panic, you can go back!',
    title: 'OOPS!!'
  },
  customQuestions: {
    question: 'Question',
    eliminate: 'Eliminatory?',
    unique: 'Single Answer',
    mult: 'Multiple Choice',
    opened: 'Free Text',
    answers: 'Options',
    rightAnswer: 'Correct Option',
    required: 'Required Field',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    duplicate: 'Duplicate Question',
    delete: 'Delete Question'
  },
  dashboard: {
    hello: 'Hello',
    seeCompanyData: 'View the company’s selection process data:',
    filterByWorkflow: 'Filter by Workflow',
    filterByRecruiter: 'Filter by Recruiter',
    chart01: 'Open Positions',
    chart02: 'Closed Positions',
    chart1: 'Average Time to Close Positions',
    chart2: 'Average Time to Close Position by Recruiter',
    chart3: 'Candidate Acquisition Source',
    chart4: 'Candidate Applications by Day',
    chart5: 'Candidates by Age Range',
    chart6: 'Candidates by Gender',
    chart7: 'Candidates by Education Level',
    chart8: 'Candidates by Marital Status',
    chart9: 'Candidates by State',
    chart10: 'Positions Closed On Time and Late by Recruiter',
    chart11: 'Candidates by Stages in the Last 6 Months',
    inTime: 'On Time',
    late: 'Late',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    errorChart: 'Insufficient data to generate the chart',
    errorCompanyData: 'Unable to load company data',
    'Solteiro(a)': 'Single',
    'Divorciado(a)': 'Divorced',
    'Viuvo(a)': 'Widowed',
    Outro: 'Other',
    Available: 'Available',
    'Rejected in Triage': 'Rejected in Triage',
    'Approved in Triage': 'Approved in Triage',
    'Denied Offer': 'Denied Offer',
    'Rejected during the process': 'Rejected during the process',
    'Solicitar documentos': 'Request Documents',
    'Coleta de documentos': 'Documents Collection',
    'No name': 'name',
    Male: 'Male',
    Female: 'Female',
    'No sex info': 'Sex information',
    'No marital status info': 'Marital status information',
    Single: 'Single',
    Married: 'Married',
    Divorced: 'Divorced',
    Widowed: 'Widowed',
    Feminino: 'Feminino',
    Masculino: 'Masculino',
    'Sem informação de sexo': 'Sem informação de sexo',
    'Sem informações do estado civil': 'Sem informações do estado civil',
    Other: 'Other',
    Hired: 'Hired',
    errorDataChart: 'Failed to retrieve data for some charts',
    errorExpiredSession: 'Session expired',
    Femenino: 'Femenino',
    Otro: 'Otro',
    'Sin información sexual': 'Sin información sexual',
    'Sin información de estado civil': 'Sin información de estado civil',
    'Soltero(a)': 'Soltero(a)',
    'Viudo(a)': 'Viudo(a)',
    'Casado(a)': 'Casado(a)'
  },
  changePassword: {
    changePassword: 'Reset Password',
    password: 'Password',
    passwordConfirmation: 'Confirm Password',
    changePasswordFail:
      'Reset failed. Your password reset link has expired or is invalid',
    changePasswordSuccess: 'Password successfully reset'
  },
  defaultWorkflowStages: {
    defaultStage: 'This stage is default and cannot be removed.',
    'Approved in Triage': 'Approved in Triage',
    Available: 'Available',
    'Failed in the selection process': 'Failed in the selection process',
    'Failed in the selection processD':
      'The candidate did not pass the selection stages such as interviews, tests, and assessments.',
    'Denied Offer': 'Denied Offer',
    Hired: 'Hired',
    'Rejected in Triage': 'Rejected in Triage',
    'Approved in TriageD': 'The candidate was approved in the triage.',
    AvailableD:
      'The candidate is interested in participating in the selection process.',
    'Denied OfferD':
      'The candidate declined the offer or withdrew from the process.',
    HiredD: 'The candidate was hired.',
    'Rejected in TriageD':
      'The candidate was eliminated from the selection process.',
    'Rejected during the process': 'Rejected during the process',
    'Rejected during the processD':
      'The candidate was rejected during the selection process.',
    'Approved in process': 'Approved in process',
    'Approved in processD':
      'The candidate was approved in the selection process.',
    'Solicitar documentos': 'Request Documents',
    'Solicitar documentosD':
      'The candidate received a link to submit the documents.',
    'Custom Step do Rafa': 'Custom Step do Rafa'
  },
  codeConfirmationModal: {
    title: 'Confirm New Email',
    subtitle: 'Enter the code you received in your new email',
    label: 'Code',
    buttonText: 'Confirm Code'
  },
  passwordConfirmationModal: {
    title: 'Email Change',
    subtitle: 'To change your email, please confirm your password',
    label: 'Password',
    buttonText: 'Confirm Password'
  },
  candidatesListContainer: {
    notfound: 'No records found.',
    select: 'Select the hiring profile',
    title: 'Choose the Hiring Profile',
    button: 'Complete',
    info: 'Now, select the hiring profile to define the documentation that the employee must submit.',
    infoBold:
      "By clicking 'Complete', the admission link will be sent to the candidate.",
    alertInfo: 'Did not find the',
    alertInfoBold: '‘Hiring Profile’',
    alertInfo2:
      'Request creation from the responsible team or access Lugarh to register.'
  },
  jobsContainer: {
    notfound: 'No records found.'
  },
  searchBox: {
    loading: 'Loading...',
    notFound: 'No results found'
  },
  subscriptionSettings: {
    headerSettigns: {
      headerTitle: 'Subscription Settings',
      headerText: 'Update or cancel subscription'
    },
    nextChargeTitle: 'Next Charge',
    nextChargeDescriptionDate: 'Your next charge will occur on ',
    nextChargeDescriptionValue: 'for a total amount of ',
    paymentMethodTitle: 'Payment Method',
    paymentMethodType: 'Credit Card',
    paymentMethodCardNumber: 'ending in ',
    paymentMethodCardNumberExpiresAt: 'Valid until: ',
    warningWithoutPaymentMethod: 'No payment method is registered.',
    descriptionWithoutPaymentMethod:
      'You can add a payment method when paying your next invoice and enable it for future payments.',
    invoices: {
      invoicesTitle: 'Invoices',
      expiresAt: 'Due Date',
      value: 'Amount',
      status: 'Status',
      link: 'View',
      statusPaid: 'Paid',
      statusPending: 'Pending',
      statusExpired: 'Expired',
      statusCanceled: 'Canceled',
      statusRefunded: 'Refunded'
    },
    unsubscribeEmail: {
      subject: 'Cancellation Request',
      body: 'Hello,\n\nPlease request the cancellation of future invoices for the Recruitment and Selection platform Recrud.',
      buttonLabel: 'Cancel Subscription'
    }
  },
  candidateInfo: {
    years: 'years'
  },
  badgeList: {
    initialScale: 'Non-Compliant',
    finalScale: 'Fully Compliant',
    candidateAssessment:
      'The final star rating is calculated as the average of ratings provided by all users, ranging from non-compliant to excellent. This rating reflects the overall assessment of the candidate.'
  },
  recruiterList: {
    managers: 'Managers',
    recruiters: 'Recruiters'
  },
  workspaces: {
    lugarh: {
      title: 'Workspaces',
      subtitle: 'Add, edit, or remove your company’s workspaces.',
      geralWorkspaceTooltip: 'This is a system default workspace.',
      button: {
        create: 'Create New Workspace'
      },
      table: {
        actions: 'Actions',
        soon: 'Coming Soon',
        noCnpj: 'No registered CNPJ',
        modal: {
          title: 'Linked Users',
          subtitle: 'View the list of users linked to this workspace'
        },
        head: {
          workspaces: 'Workspaces',
          users: 'Users',
          cnpj: 'CNPJ'
        },
        buttons: {
          edit: 'Edit',
          exclude: 'Delete'
        }
      }
    }
  }
}

export default en
