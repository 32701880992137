const es: AppLocale = {
  languages: {
    'pt-BR': 'Português',
    'en-US': 'English'
  },
  notifications: {
    lugarh: {
      error: {
        'An error occurred while processing your request':
          'Ocurrió un error al procesar su solicitud',
        'Not authorized, please check the workspace':
          'No autorizado, por favor verifique el espacio de trabajo',
        permission: 'No tiene permiso para acceder a este recurso'
      },
      success: {
        'Your request was successfully completed':
          'Su solicitud se completó con éxito'
      }
    }
  },
  goHomeButton: {
    lugarh: {
      goHome: 'Ir a la página de inicio'
    }
  },
  errors: {
    onlySpaces: 'No puede contener solo espacios en blanco.',
    minBudget: 'El valor mínimo es 650',
    errorTitle: 'Error',
    forbbidenError: 'No tiene permiso para acceder a este recurso.',
    maxLength: 'Solo se permiten 2.000 caracteres',
    requiredField: 'Campo obligatorio',
    specialCharacters: 'Solo se permiten letras y números',
    dateInvalidFormat:
      'Por favor, ingrese su fecha de nacimiento en el formato correcto.',
    deadlineInvalidFormat:
      'Por favor, ingrese un plazo de finalización válido.',
    futureDate:
      'La fecha de nacimiento no puede estar en el futuro. Por favor, ingrese una fecha válida.',
    invalidBirthdate:
      'La fecha de nacimiento proporcionada está fuera del rango aceptable. Asegúrese de ingresar una fecha válida.',
    invalidStartDateFormat:
      'Por favor, ingrese la fecha de inicio en el formato correcto.',
    futureStartDate: 'La fecha de inicio no puede estar en el futuro.',
    invalidStartDate:
      'La fecha de inicio proporcionada está fuera del rango aceptable. Asegúrese de ingresar una fecha válida.',
    invalidEndDateFormat:
      'Por favor, ingrese la fecha de finalización en el formato correcto.',
    invalidEndDateOrder:
      'La fecha de finalización del curso debe ser posterior a la fecha de inicio.',
    invalidEndDate:
      'La fecha de finalización proporcionada está fuera del rango aceptable. Asegúrese de ingresar una fecha válida.',
    futureEndDate:
      'La fecha de finalización del curso no puede estar en el futuro.',
    expiredtoken: 'Su sesión ha expirado',
    emailInvalid: '¡Correo electrónico inválido!',
    fieldInvalid: '¡Campo inválido!',
    fieldMinLength: 'Debe tener al menos 3 caracteres.',
    onRequestFail:
      'Ocurrió un error al obtener los datos, por favor intente de nuevo',
    addNoteError: 'Error al agregar la nota, por favor intente de nuevo',
    addReview: 'Error al agregar la nota',
    cnpj: 'El formato del CNPJ es incorrecto',
    cnpjMaxLength: 'Se alcanzó el límite máximo de 18 caracteres.',
    cpfCnpjInvalid: 'El número ingresado es inválido.',
    cpfCnpjMinLength: 'Debe tener al menos 14 caracteres.',
    cpfCnpjMaxLength: 'Se alcanzó el límite máximo de 18 caracteres.',
    passwordMin: 'La contraseña debe tener al menos 6 caracteres.',
    passwordConfirm: '¡Las contraseñas deben coincidir!',
    finalizeCompanyRegistration:
      'Para acceder a este recurso, debe completar el registro de la empresa.',
    invalidSlug: '¡Enlace de la página de carreras inválido!',
    linkAvailable: '¡Enlace disponible!',
    linkUnavailable: 'Enlace no disponible',
    invalidComapanyName:
      'El nombre de la empresa no puede contener caracteres especiales',
    applicationError: 'Este registro ya existe en nuestras bases de datos',
    timeoutError: 'El servidor tardó en responder. Intente nuevamente',
    emptyCity: 'El campo de localidad debe ser informado.',
    limitReached: 'Este recurso ha alcanzado el límite del plan.',
    creditCardNumberInvalid: 'Número de tarjeta inválido',
    creditCardCvvInvalid: 'CVV inválido',
    creditCardExpirationMonthInvalid: 'Mes inválido',
    creditCardExpirationYearInvalid: 'Año inválido',
    hiringWorkflowNameMaxLength:
      'Se alcanzó el límite máximo de 80 caracteres.',
    hiringWorkflowDescriptionMaxLength:
      'Se alcanzó el límite máximo de 300 caracteres.',
    downloadAttachedFile: 'El archivo no existe o ya no está disponible',
    serverError: 'El servidor no pudo completar su solicitud.'
  },
  success: {
    candidateMoved: 'Candidato trasladado con éxito',
    noteAdded: 'Nota añadida con éxito',
    addReview: 'Nota añadida con éxito',
    applicationSuccess: 'Éxito',
    jobMoved: 'Oferta trasladada con éxito',
    jobDuplicated: 'Oferta duplicada con éxito'
  },
  cookie: {
    message:
      'Utilizamos cookies para ayudar a personalizar el contenido y proporcionar una experiencia más segura; no almacenamos datos personales. Al navegar por las páginas, usted acepta nuestra Política de Privacidad.',
    buttonAccept: 'Aceptar',
    buttonRefuse: 'Rechazar'
  },
  common: {
    current: 'Actual',
    gender: {
      male: 'Masculino',
      female: 'Femenino',
      other: 'Otro'
    },
    civilStatus: {
      single: 'Soltero',
      married: 'Casado',
      divorced: 'Divorciado',
      widowed: 'Viudo',
      other: 'Otros'
    },
    applyToJob: 'Postularse a la oferta'
  },
  createWorkflow: {
    create: 'Crear Flujo de Contratación',
    update: 'Actualizar Flujo de Contratación'
  },
  home: {
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description:
        'La próxima generación de gestión de relaciones con candidatos.'
    },
    content: 'Edite el archivo src/Home.tsx y guarde para actualizar'
  },
  uploadBox: {
    minSizeRecommended: 'tamaño recomendado 400 x 50 px',
    logo: 'Logo',
    suggestedSize: 'Tamaño recomendado'
  },
  searchBar: {
    allCandidates: 'Ver todos los candidatos',
    allJobs: 'Ver todas las vacantes',
    placeholder: 'Busque candidatos, vacantes y etiquetas',
    results: {
      candidates: 'Candidatos encontrados',
      jobs: 'Vacantes encontradas'
    },
    searching: 'Buscando...',
    notFound: 'No se encontraron resultados',
    appliedFor: 'aplicó para {{number}} vacantes',
    candidatesNumber: '{{number}} candidatos',
    createdAt: 'Creado el {{date}}',
    candidates: 'candidatos'
  },
  header: {
    about: 'Sobre Nosotros',
    terms: 'Términos de Uso',
    signup: 'Crear Cuenta'
  },
  headerSettings: {
    workspaces: 'Espacios de trabajo',
    myProfile: 'Mi perfil',
    profileEdit: 'Editar perfil',
    company: 'Empresa',
    subscriptionSettings: 'Configuraciones de suscripción',
    companySettings: 'Configuraciones de la empresa',
    jobPostings: 'Gestión de ofertas de empleo',
    admission: 'Admisión',
    hiringWorkflows: 'Flujos de contratación',
    changeCompany: 'Cambiar de empresa',
    changeWorkspace: 'Cambiar de espacio de trabajo',
    manageWorkspace: 'Gestionar espacio de trabajo',
    manageTeam: 'Gestionar equipo',
    language: 'Idioma',
    english: 'Inglés',
    portuguese: 'Portugués',
    spanish: 'Español',
    session: 'Sesión',
    leave: 'Salir',
    hello: 'Hola',
    sections: {
      dashboard: 'Panel',
      jobs: 'Ofertas de empleo',
      candidates: 'Candidatos',
      settings: 'Configuraciones',
      admission: 'Admisión de candidatos',
      newFlag: 'NUEVO'
    },
    helpButtonDescription: 'Ayuda',
    helpButtonSubject: 'Ayuda en la gestión de candidatos'
  },
  user: {
    role: {
      admin: 'Administrador',
      admin_rocketmat: 'Administrador',
      manager: 'Gerente',
      recruiter: 'Reclutador',
      recruiter_webtactics: 'Reclutador Webtactics'
    },
    indicationButton: 'Indicar Recrud',
    copyLinkSuccessMessage: 'Enlace copiado con éxito',
    here: 'Aquí'
  },
  router: {
    pageNotFound: 'Página no encontrada. Por favor, contacte con el soporte.'
  },
  login: {
    lugarh: {
      title:
        '[[span]] Revolucione su proceso de RRHH[[/span]] [[span.highlight]]de principio a fin[[/span]] [[span]]en un único Lugarh[[/span]]',
      subtitle:
        'Además de una poderosa herramienta de Reclutamiento y Selección, ofrecemos un ecosistema completo para',
      itemOne: 'Admisión',
      itemTwo: 'Gestión de personas',
      itemThree: 'BPO de RRHH',
      specialist: 'Hable con un especialista',
      form: {
        title: 'Ingrese a su cuenta',
        email: {
          placeholder: 'Correo electrónico'
        },
        password: {
          placeholder: 'Contraseña',
          show: 'Mostrar',
          hide: 'Ocultar'
        },
        keepConnected: {
          label: 'Mantenerse conectado'
        },
        submitButton: 'Entrar',
        submitButtonLoading: 'Iniciando sesión...',
        errors: {
          emailRequired: 'Correo electrónico es obligatorio',
          emailInvalid: 'Correo electrónico inválido',
          passwordMin: 'Mínimo 6 caracteres',
          passwordRequired: 'Contraseña es obligatoria'
        }
      },
      forgotPassword: '¿Olvidó la contraseña?',
      noAccount: '¿Aún no tiene una cuenta?',
      createAccount: 'Cree ahora',
      chooseLanguage: 'Elija el idioma',
      errors: {
        blockedUser:
          'Usuario sin permiso de acceso. Contacte al administrador para más información.',
        expiredSubscription:
          'Suscripción expirada. Contacte al administrador para más información.',
        expiredSession: 'Sesión expirada. Inicie sesión nuevamente.',
        loginError: 'Usuario o contraseña inválidos. Intente nuevamente.'
      }
    },
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description:
        'La próxima generación de gestión de relaciones con candidatos'
    },
    welcome: 'Bienvenido a',
    firstParagraph:
      'Una plataforma de reclutamiento simple, fácil e inteligente.',
    secondParagraph: '¡Disfrute de la experiencia!',
    loginTitle: 'Iniciar sesión en su cuenta',
    firstname: 'Nombre',
    lastname: 'Apellido',
    email: 'Correo electrónico',
    password: 'Contraseña',
    loginCheckBox: 'Mantenerse conectado',
    forgotPassword: '¿Olvidó la contraseña?',
    loginAccount: 'Iniciar sesión en su cuenta',
    registerTitle: '¡Cree su cuenta!',
    repeatPassword: 'Repetir contraseña',
    registerCheckBox: '¡Acepto los términos y condiciones!',
    registerNow: 'Regístrate ahora',
    aboutUs: 'Sobre nosotros',
    privacy: 'Políticas de privacidad',
    terms: 'Términos',
    whatsRecrud: '¿Qué es Recrud?',
    loginError: 'Usuario o contraseña inválidos. Intente nuevamente',
    expiredSession: 'Sesión expirada. Inicie sesión nuevamente',
    blockedUser:
      'Usuario sin permiso de acceso. Contacte al administrador para más información.',
    expiredSubscription:
      'Suscripción expirada. Contacte al administrador para más información.',
    signupSuccess: '¡Registro exitoso!',
    registerError:
      'Se produjo un error al registrar, por favor intente nuevamente',
    registerErrorDataExists:
      'El correo electrónico ya está en uso, intente nuevamente',
    register: 'Registrar',
    login: 'Entrar',
    passwordConfirm: '¡Las contraseñas no coinciden!',
    language: 'Idioma',
    acquisitionChannel: '¿Cómo nos encontró?'
  },
  card: {
    lugarh: {
      title:
        'Hable con un especialista y conozca los mejores planes para su empresa',
      subtitle:
        'Para descubrir los planes que mejor se adaptan a sus necesidades, le invitamos a hablar con nuestros especialistas, que están listos para ayudarle de manera personalizada.',
      ctaButton: 'Conocer los planes',
      backButton: 'Volver'
    }
  },
  selectableList: {
    lugarh: {
      searchInputPlaceholder: 'Buscar por nombre',
      selectAllLabel: 'Seleccionar todos',
      notFound: 'No se encontraron resultados para la búsqueda'
    }
  },
  workspaceForm: {
    lugarh: {
      fetchUsersError: 'Error al buscar usuarios',
      placeholder: {
        name: 'Nombre del Workspace',
        slug: 'Apodo del Workspace',
        cnpj: 'CNPJ',
        postcode: 'Código Postal',
        address_line1: 'Dirección (Calle y Número)',
        address_line2: 'Complemento',
        city: 'Ciudad',
        region: 'Estado',
        country: 'País'
      },
      addAddressLabel: 'Agregar Dirección',
      submitButton: {
        stepOne: 'Continuar',
        stepTwo: 'Enviar',
        loading: 'Procesando...'
      },
      errors: {
        'Nome do workspace deve ter no máximo 80 caracteres':
          'El nombre del workspace debe tener un máximo de 80 caracteres',
        'Nome do workspace é obrigatório':
          'El nombre del workspace es obligatorio',
        'CNPJ deve conter apenas números': 'El CNPJ debe contener solo números',
        'CNPJ deve ter 14 dígitos': 'El CNPJ debe tener 14 dígitos',
        'CEP é obrigatório': 'El código postal es obligatorio',
        'Endereço é obrigatório': 'La dirección es obligatoria',
        'Cidade é obrigatória': 'La ciudad es obligatoria',
        'País é obrigatório': 'El país es obligatorio',
        'Adicione pelo menos um usuário': 'Agregue al menos un usuario',
        'É obrigatório adicionar usuários': 'Es obligatorio agregar usuarios'
      }
    }
  },
  updateWorkspaceForm: {
    lugarh: {
      fetchUsersError: 'Error al buscar usuarios',
      placeholder: {
        name: 'Nombre del Workspace *',
        slug: 'Apodo del Workspace (Opcional)',
        cnpj: 'CNPJ (Opcional)',
        postcode: 'Código Postal',
        address_line1: 'Dirección (Calle y Número)',
        address_line2: 'Complemento',
        city: 'Ciudad',
        region: 'Estado',
        country: 'País'
      },
      addAddressLabel: 'Agregar dirección al workspace',
      submitButton: {
        stepOne: 'Continuar',
        stepTwo: 'Guardar información',
        loading: 'Procesando...',
        back: 'Volver'
      },
      errors: {
        'Nome do workspace deve ter no máximo 80 caracteres':
          'El nombre del workspace debe tener como máximo 80 caracteres',
        'Nome do workspace é obrigatório':
          'El nombre del workspace es obligatorio',
        'CNPJ deve conter apenas números': 'El CNPJ debe contener solo números',
        'CNPJ deve ter 14 dígitos': 'El CNPJ debe tener 14 dígitos',
        'CEP é obrigatório': 'El código postal es obligatorio',
        'Endereço é obrigatório': 'La dirección es obligatoria',
        'Cidade é obrigatória': 'La ciudad es obligatoria',
        'País é obrigatório': 'El país es obligatorio',
        'Adicione pelo menos um usuário': 'Agregue al menos un usuario',
        'É obrigatório adicionar usuários': 'Es obligatorio agregar usuarios'
      }
    }
  },
  editUserForm: {
    lugarh: {
      fetchWorkspacesError: 'Error al buscar workspaces',
      placeholder: {
        firstname: 'Nombre',
        lastname: 'Apellido',
        password: 'Contraseña',
        email: 'Correo electrónico',
        role: {
          select: 'Seleccione el cargo',
          admin: 'Administrador',
          manager: 'Gerente',
          recruiter: 'Reclutador'
        }
      },
      submitButton: {
        stepOne: 'Continuar',
        stepTwo: 'Enviar',
        loading: 'Procesando...',
        back: 'Volver'
      },
      errors: {
        'Required field': 'Campo obligatorio',
        'Nome do workspace deve ter no máximo 80 caracteres':
          'El nombre del workspace debe tener como máximo 80 caracteres',
        'Nome do workspace é obrigatório':
          'El nombre del workspace es obligatorio',
        'CNPJ deve conter apenas números': 'El CNPJ debe contener solo números',
        'CNPJ deve ter 14 dígitos': 'El CNPJ debe tener 14 dígitos',
        'CEP é obrigatório': 'El código postal es obligatorio',
        'Endereço é obrigatório': 'La dirección es obligatoria',
        'Cidade é obrigatória': 'La ciudad es obligatoria',
        'País é obrigatório': 'El país es obligatorio',
        'Adicione pelo menos um usuário': 'Agregue al menos un usuario',
        'É obrigatório adicionar usuários': 'Es obligatorio agregar usuarios'
      }
    },
    errors: {
      'Required field': 'Campo obligatorio',
      'Password must have at least 6 characters':
        'La contraseña debe tener al menos 6 caracteres',
      'Select at least one workspace': 'Seleccione al menos un workspace',
      'Workspace is a required field': 'El workspace es un campo obligatorio'
    }
  },
  createWorkspaceModal: {
    lugarh: {
      title: 'Agregar workspace',
      subtitle: 'Ingrese la información a continuación para continuar',
      stepOne: '1. Datos básicos',
      stepTwo: '2. Usuarios'
    }
  },
  deleteWorkspaceModal: {
    lugarh: {
      title: 'Eliminar espacio de trabajo',
      subtitle:
        'Esta acción es permanente, ¿está seguro de que desea eliminar?',
      back: 'Cancelar',
      delete: 'Quiero eliminar',
      requestMessage: {
        '204': 'Espacio de trabajo eliminado con éxito.',
        '401': 'Fallo en la autenticación o credenciales inválidas.',
        '403':
          'El espacio de trabajo no puede ser eliminado porque está vinculado a uno o más trabajos.',
        '404': 'Espacio de trabajo no encontrado.',
        '500': 'Error interno del servidor.'
      },
      loading: 'Procesando...'
    }
  },
  updateWorkspaceModal: {
    lugarh: {
      title: 'Editar espacio de trabajo',
      subtitle: 'Modifique la información a continuación para continuar',
      stepOne: '1. Datos básicos',
      stepTwo: '2. Usuarios',
      requestMessage: {
        '200': 'Espacio de trabajo modificado con éxito.',
        '401': 'Fallo en la autenticación o credenciales inválidas.',
        '403': 'El espacio de trabajo no puede ser modificado.',
        '404': 'Espacio de trabajo no encontrado.',
        '500': 'Error interno del servidor.'
      }
    }
  },
  duplicateWorkspaceModal: {
    title: 'Duplicar al espacio de trabajo',
    button: 'Duplicar al espacio de trabajo'
  },
  createUserModal: {
    lugarh: {
      title: 'Añadir Usuario',
      subtitle: 'Ingrese la información a continuación para continuar',
      stepOne: '1. Datos del usuario',
      stepTwo: '2. Seleccione los espacios de trabajo'
    }
  },
  editUserModal: {
    lugarh: {
      title: 'Editar Usuario',
      subtitle: 'Ingrese la información a continuación para continuar',
      stepOne: '1. Datos del usuario',
      stepTwo: '2. Seleccione los espacios de trabajo'
    }
  },
  changeEnvironmentModal: {
    lugarh: {
      title: 'Espacios de Trabajo',
      subtitle: 'Elija un espacio de trabajo para trabajar',
      actions: {
        change: 'Cambiar Espacio de Trabajo',
        manage: 'Gestionar Espacios de Trabajo'
      }
    }
  },
  singleSelectableList: {
    lugarh: {
      placeholder: {
        search: 'Buscar por nombre'
      },
      searchNotFound: 'Ningún ítem encontrado'
    },
    recrud: {
      placeholder: {
        search: 'Buscar por nombre'
      },
      searchNotFound: 'Ningún ítem encontrado'
    }
  },
  moveWorkspaceModal: {
    lugarh: {
      modalTitle: 'Mover puesto a otro Workspace',
      modalSubtitle: 'Seleccione el workspace al que desea mover el puesto',
      submitButton: 'Mover Puesto'
    }
  },
  resetPassword: {
    lugarh: {
      form: {
        title: 'Restablecer Contraseña',
        password: {
          show: 'Mostrar',
          hide: 'Ocultar'
        },
        newPassword: {
          placeholder: 'Nueva Contraseña'
        },
        confirmNewPassword: {
          placeholder: 'Confirmar Nueva Contraseña'
        },
        submitButton: 'Restablecer',
        errors: {
          newPasswordMin: 'Mínimo 6 caracteres',
          newPasswordRequired: 'La nueva contraseña es obligatoria',
          confirmNewPasswordRequired:
            'La confirmación de contraseña es obligatoria',
          confirmNewPasswordMatch: 'Las contraseñas no coinciden'
        },
        goToLogin: 'Volver a la pantalla de inicio de sesión'
      },
      resetPasswordFail: 'Error al restablecer la contraseña.',
      resetPasswordSuccess: 'Contraseña restablecida con éxito.'
    }
  },
  forgotPassword: {
    lugarh: {
      form: {
        title: 'Olvidé mi contraseña',
        email: {
          placeholder: 'Correo electrónico'
        },
        submitButton: 'Recuperar contraseña',
        errors: {
          emailRequired: 'El correo electrónico es obligatorio',
          emailInvalid: 'Correo electrónico inválido'
        },
        goToLogin: 'Volver a la pantalla de inicio de sesión'
      }
    },
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description:
        'La próxima generación de gestión de relaciones con candidatos'
    },
    welcome: 'Bienvenido a',
    firstParagraph: 'Gestione los procesos de selección de su empresa',
    secondParagraph: 'de manera simple e inteligente!',
    forgotPassword: 'Olvidé mi contraseña',
    cardParagraph:
      'Ingrese su correo electrónico para recibir instrucciones sobre cómo cambiar la contraseña',
    recoveryPassword: 'Recuperar contraseña',
    emailValidationError: 'Por favor, ingrese un correo electrónico válido',
    emailNotFound: 'Correo electrónico no registrado, intente nuevamente',
    sendEmailFail: 'Error al enviar el correo electrónico de recuperación',
    sendEmailSuccess: 'Correo electrónico de recuperación enviado con éxito'
  },
  plans: {
    metatags: {
      title: 'string',
      description: 'string',
      url: 'string'
    },
    selectPlanTitle: 'Seleccione un plan',
    plansCardBasic: {
      title: 'Básico',
      subTitle: 'Para empresas con pocas vacantes',
      planValue: 'R$29,90',
      planPeriod: '/mensual',
      feat1: 'Hasta 2 vacantes por mes',
      feat2: 'Usuarios ilimitados',
      feat3: 'Candidatos ilimitados'
    },
    plansCardSmart: {
      title: 'Smart',
      subTitle: 'Para empresas con un volumen medio de vacantes',
      planValue: 'R$49,90',
      planPeriod: '/mensual',
      feat1: 'Hasta 5 vacantes por mes',
      feat2: 'Usuarios ilimitados',
      feat3: 'Candidatos ilimitados'
    },
    plansCardAdvanced: {
      title: 'Avanzado',
      subTitle: 'Para empresas con un alto volumen de vacantes',
      planValue: 'R$69,90',
      planPeriod: '/mensual',
      feat1: 'Hasta 10 vacantes por mes',
      feat2: 'Usuarios ilimitados',
      feat3: 'Candidatos ilimitados'
    },
    plansCardEnterprise: {
      title: 'Enterprise',
      subTitle: 'Para un gran volumen de vacantes',
      planValue: 'Cotización personalizada',
      planPeriod: '',
      feat1: 'Más de 10 vacantes por mes',
      feat2: 'Usuarios ilimitados',
      feat3: 'Candidatos ilimitados',
      selectButton: 'Hablar con un especialista'
    },
    selectButton: 'Empieza ya'
  },
  checkoutPlan: {
    metatags: {
      title: 'string',
      description: 'string',
      url: 'string'
    },
    companyFormTitle: 'Datos de la Empresa',
    paymentFormTitle: 'Método de pago',
    creditCardLabel: 'Tarjeta de crédito',
    cardNumber: 'Número de tarjeta',
    cvv: 'CVV',
    expirationMonth: 'Mes de vencimiento',
    expirationYear: 'Año de vencimiento',
    cardHolderName: 'Titular de la tarjeta',
    changePlanButton: 'Cambiar de plan',
    paymentButton: 'Contratar',
    cardTokenizationError: 'Verifique los datos de la tarjeta de crédito',
    iugoCardTokenizationFailure:
      'Ocurrió un error interno al procesar la tarjeta',
    billingAddressFromTitle: 'Dirección de Facturación',
    phone: 'Teléfono',
    cpfCnpj: 'CPF/CNPJ',
    zipcode: 'CEP',
    district: 'Barrio',
    street: 'Calle',
    number: 'Número',
    complement: 'Complemento',
    city: 'Ciudad',
    state: 'Estado'
  },
  myProfile: {
    banner: {
      title: 'Editar Perfil',
      text: 'Edita tu información personal, contraseñas y mucho más.'
    },
    form: {
      firstname: 'Nombre',
      lastname: 'Apellido',
      email: 'Correo electrónico',
      password: 'Contraseña',
      confirmPassword: 'Confirmar contraseña',
      save: 'Guardar Cambios',
      successEditMessage: '¡Cambios guardados exitosamente!'
    },
    confirmationModal: {
      wrongPassword: 'Contraseña incorrecta',
      failToSend: 'Error al enviar el correo',
      passwordConfirmationSucess:
        'Contraseña confirmada. Se ha enviado un código a tu nuevo correo electrónico',
      unavailable: 'No es posible realizar la operación en este momento',
      wrongCode: 'Error al validar el código'
    }
  },
  candidate: {
    lugarh: {
      sectionTab: {
        notDefined:
          '¡Ops! No se ha seleccionado ninguna opción en el menú lateral.'
      },
      basicInfo: {
        createdAt: 'Registro',
        updatedAt: 'Última actualización',
        years: 'años',
        missingData: {
          title: 'Cargo no informado',
          location: 'Ubicación no informada',
          age: 'Fecha de nacimiento no informada',
          salaryExpectation: 'Salario no informado',
          email: 'Correo electrónico no informado',
          phone: 'Teléfono no informado',
          linkedin: 'Linkedin no informado'
        },
        copyToClipboard: {
          success: '¡Copiado con éxito!',
          error:
            'Error al copiar. Información no proporcionada por el candidato.',
          errorWhileCopying: 'Error al copiar.'
        }
      },
      asideSectionNav: {
        curriculum: 'Currículum',
        history: 'Historial',
        export: 'Exportar',
        apply: 'Aplicar para la vacante'
      }
    }
  },
  addCandidate: {
    lugarh: {
      title: 'Añadir Candidato',
      subtitle:
        'Este es tu panel de datos generales del proceso de selección de tu empresa',
      submitFormButton: 'GUARDAR INFORMACIÓN',
      personalDataBox: {
        title: 'Datos personales',
        firstname: 'Nombre *',
        lastname: 'Apellido *',
        birthdate: 'Fecha de nacimiento',
        maritalStatus: 'Estado civil',
        dateFormat: 'DD/MM/YYYY',
        maritalStatusOptions: {
          select: 'Seleccione',
          divorced: 'Divorciado(a)',
          married: 'Casado(a)',
          single: 'Soltero(a)',
          widowed: 'Viudo(a)',
          other: 'Otro(a)'
        },
        sex: 'Género',
        sexOptions: {
          female: 'Femenino',
          male: 'Masculino',
          other: 'Otro'
        },
        phone: 'Teléfono móvil',
        email: 'Correo electrónico',
        geoLocation: 'Ubicación *',
        cityRelocation: '¿Disponibilidad para mudanza?',
        cityRelocationOptions: {
          yes: 'Sí',
          no: 'No'
        },
        salary_expectation: 'Expectativa salarial',
        linkedin_profile: 'LinkedIn',
        facebook_profile: 'Facebook',
        twitter_profile: 'Twitter',
        origin: 'Origen del candidato',
        about: 'Sobre *'
      },
      languageBox: {
        title: 'Idioma',
        language: 'Idioma *',
        level: 'Nivel *',
        levelOptions: {
          basic: 'Básico',
          intermediate: 'Intermedio',
          fluent: 'Avanzado',
          native: 'Nativo'
        },
        button: 'Añadir nuevo idioma',
        deleteButton: 'Eliminar'
      },
      educationBox: {
        title: 'Educación',
        course: 'Curso *',
        institution: 'Institución',
        startDate: 'Inicio *',
        endDate: 'Fin *',
        level: 'Nivel *',
        dateFormat: 'DD/MM/YYYY',
        levelOptions: {
          incomplete_middleschool: 'Educación Primaria Incompleta',
          middleschool: 'Educación Primaria Completa',
          incomplete_highschool: 'Educación Secundaria Incompleta',
          highschool: 'Educación Secundaria Completa',
          undergraduate: 'Pregrado',
          postgraduate: 'Postgrado',
          masters: 'Maestría',
          doctorate: 'Doctorado'
        },
        description: 'Sobre',
        button: 'Añadir nuevo curso',
        deleteButton: 'Eliminar'
      },
      certificateBox: {
        title: 'Certificados y logros',
        name: 'Nombre *',
        hours: 'Horas *',
        button: 'Añadir nuevo logro',
        deleteButton: 'Eliminar'
      },
      experienceBox: {
        title: 'Experiencia profesional',
        role: 'Puesto *',
        company: 'Empresa',
        startDate: 'Fecha de inicio *',
        endDate: 'Fecha de fin',
        description: 'Descripción de las actividades',
        currentJob: 'Estoy trabajando en esta empresa actualmente *',
        button: 'Añadir nueva experiencia',
        deleteButton: 'Eliminar',
        dateFormat: 'DD/MM/YYYY'
      },
      otherInformationBox: {
        title: 'Otra información',
        other_info: 'Otra información'
      },
      attachmentsBox: {
        title: 'Envío de archivos',
        button: 'Añadir archivo',
        attachmentError:
          'Sólo se permiten archivos en PDF, PNG, JPEG y JPG de hasta 3 MB.',
        underlineText: 'Haz clic para subir',
        description: 'o arrastra y suelta el archivo aquí',
        fileTypes: 'PDF, PNG o JPG (máx. 3 MB)',
        success: 'Completo',
        uploadFailed: 'La carga falló',
        largeDocumentError: 'Documento demasiado pesado  •  Falló'
      },
      messages: {
        success: '¡Candidato añadido con éxito!'
      },
      submitButton: 'Continuar'
    },
    button: {
      addAttachments: 'Añadir archivo'
    },
    banner: {
      title: 'Añadir Candidato',
      text: 'Añadir candidato a la base de datos'
    },
    form: {
      email: 'Correo electrónico',
      firstname: 'Nombre',
      lastname: 'Apellido',
      about: 'Sobre',
      geoLocation: 'Ubicación',
      phone: 'Teléfono',
      maritalStatus: 'Estado Civil',
      sex: 'Género',
      birthdate: 'Fecha de Nacimiento',
      salaryExpectation: 'Expectativa Salarial',
      cityRelocation: '¿Disponibilidad para mudanza?',
      linkedinProfile: 'LinkedIn',
      facebookProfile: 'Facebook',
      twitterProfile: 'Twitter',
      save: 'Guardar Cambios',
      successMessage: '¡Cambios guardados exitosamente!'
    },
    titleBox: {
      personalData: 'Datos personales',
      language: 'Idioma',
      education: 'Educación',
      certificatesAndAchievements: 'Certificados y logros',
      experience: 'Experiencia profesional',
      otherInformation: 'Otra información',
      attachments: 'Envío de archivos'
    },
    languageBox: {
      language: 'Idioma',
      level: 'Nivel'
    },
    educationBox: {
      course: 'Curso',
      institution: 'Institución',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de finalización',
      level: 'Nivel',
      about: 'Sobre'
    },
    certificateBox: {
      name: 'Nombre',
      hours: 'Horas'
    },
    experienceBox: {
      role: 'Puesto',
      company: 'Empresa',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de finalización',
      currentJob: 'Empleo actual',
      description: 'Descripción de las actividades'
    },
    otherInformation: 'Otra información',
    attachmentSubTitleDescription:
      'Por favor, adjunta los documentos justificativos. [Ej.: Currículum, CV y/o Carta de Presentación]',
    attachmentSubTitleFileDescription: 'Adjuntos',
    attachmentError:
      'Sólo se permiten archivos en PDF, PNG, JPEG y JPG de hasta 5 MB.',
    sexOptions: {
      female: 'Femenino',
      male: 'Masculino',
      other: 'Otro'
    },
    maritalStatusOptions: {
      select: 'Seleccione',
      divorced: 'Divorciado(a)',
      married: 'Casado(a)',
      single: 'Soltero(a)',
      widowed: 'Viudo(a)',
      other: 'Otro(a)'
    },
    cityRelocationOptions: {
      yes: 'Sí',
      no: 'No'
    }
  },
  candidateDetail: {
    movedTo: 'moved to stage {{stageName}} by {{authorName}}.',
    appliedTo: 'applied for the job.',
    current: 'ACTUALMENTE'
  },
  companySettings: {
    save: '¡Guardar Cambios!',
    banner: {
      title: 'Configuración de la Empresa',
      text: 'Edite la información sobre la empresa, la página de talentos, los datos sensibles y mucho más.'
    },
    color: {
      companyPageHeaderColor: 'Color del encabezado de la página de carrera',
      companyPageFontColor: 'Color de la fuente de la página de carrera',
      companyPageButtonColor: 'Color de los botones de la página de carrera',
      companyPageButtonFontColor:
        'Color de la fuente de los botones de la página de carrera'
    },
    careerPageSetting: {
      title: 'Configuraciones de la Página de Carrera',
      showHomeButtonTitle: 'Mostrar botón de inicio',
      showHomeButtonText: 'Decida si el candidato verá el botón de inicio.',
      showMainPageTitle: 'Mostrar página principal',
      showMainPageText:
        'Decida si el candidato podrá acceder a la página principal.',
      careerPageLabel: '¿Activar?'
    },
    lgpd: {
      title: 'Datos personales / Datos sensibles / LGPD',
      label: {
        question: '¿Activar?',
        required: '¿Obligatorio?'
      },
      askPhoneTitle: 'Preguntar número de teléfono',
      askPhoneText:
        'Decida si el candidato proporcionará el número de teléfono y si usted manejará esta información.',
      civilStatusTitle: 'Estado civil',
      civilStatusText:
        'Decida si el candidato proporcionará su estado civil y si usted manejará esta información.',
      askGenderTitle: 'Preguntar Género',
      askGenderText:
        'Decida si el candidato proporcionará su género y si usted manejará esta información.',
      askBirthdateTitle: 'Preguntar Fecha de Nacimiento',
      askBirthdateText:
        'Decida si el candidato proporcionará la fecha de nacimiento y si usted manejará esta información.',
      askWagePretensionTitle: 'Preguntar Pretensión Salarial',
      askWagePretensionText:
        'Decida si el candidato proporcionará su pretensión salarial y si usted manejará esta información.',
      askMovingAvailabilityTitle: 'Preguntar Disponibilidad para Mudanza',
      askMovingAvailabilityText:
        'Decida si el candidato proporcionará su disponibilidad para mudanza y si usted manejará esta información.',
      askAboutLinkedinProfileTitle: 'Preguntar sobre el perfil de LinkedIn',
      askAboutLinkedinProfileText:
        'Decida si el candidato proporcionará su perfil de LinkedIn y si usted manejará esta información.',
      askAboutFacebookProfileTitle: 'Preguntar sobre el perfil de Facebook',
      askAboutFacebookProfileText:
        'Decida si el candidato proporcionará su perfil de Facebook y si usted manejará esta información.',
      askAboutTwitterProfileTitle: 'Preguntar sobre el perfil de Twitter',
      askAboutTwitterProfileText:
        'Decida si el candidato proporcionará su perfil de Twitter y si usted manejará esta información.',
      termsOfUseTitle: 'Términos de uso',
      termsOfUseText: 'Ingrese un enlace a los términos de uso.'
    },
    companyForm: {
      name: 'Razón Social',
      fancyName: 'Nombre Comercial',
      cnpj: 'CNPJ',
      description: 'Descripción de la empresa',
      location: 'Ubicación',
      site: 'Enlace al sitio de carreras'
    },
    changesSaved: '¡Cambios guardados!',
    messageChangesSaved:
      'La página de su empresa ha sido modificada. Puede verificarla accediendo al enlace:'
  },
  onboarding: {
    buttons: {
      next: 'Siguiente',
      previous: 'Anterior',
      skip: 'Omitir'
    },
    dashboard: {
      finishOnboardingButton: 'Finalizar Tour Inicial',
      content: [
        {
          title: 'Bienvenido a Recrud',
          text: 'Como es tu primera vez aquí, te asistiremos con este simple tutorial para que conozcas mejor Recrud y puedas explorarlo de la mejor manera.'
        },
        {
          title: 'La barra de navegación',
          text: 'Esta es la barra de navegación del sistema. Desde aquí puedes acceder a tus vacantes, a la base de candidatos y volver al panel principal.'
        },
        {
          title: 'La barra de búsqueda',
          text: 'Si deseas encontrar vacantes o candidatos específicos, puedes buscar aquí y ahorrar tiempo.'
        },
        {
          title: 'El Panel de administración',
          text: 'Si eres un gerente, desde aquí podrás configurar la página de tu empresa, gestionar tu flujo de contratación y añadir o eliminar usuarios de tu equipo.<br /><br />Si eres un reclutador, desde aquí podrás editar tu perfil y cambiar tu contraseña.'
        },
        {
          title: 'El Dashboard',
          text: 'Sigue los datos de tu proceso de contratación desde el dashboard, como el tiempo medio de contratación, los números de tu base de candidatos y mucho más.'
        },
        {
          title: 'Recrud',
          text: 'Ahora conoces bien la parte inicial del sistema. Navega a las otras páginas para continuar el tutorial.'
        }
      ]
    },
    jobs: {
      finishOnboardingButton: 'Finalizar tour por la pantalla de vacantes',
      content: [
        {
          title: 'Vacantes',
          text: 'Este es el panel de vacantes, donde se muestran todas las vacantes activas de tu empresa para que los candidatos puedan postularse. Vamos a explorar más detalles sobre esta página.'
        },
        {
          title: 'Crear una nueva vacante',
          text: '¿Necesitas crear una nueva vacante? Para registrarla, haz clic en el botón indicado y completa el formulario de registro.'
        },
        {
          title: 'Estado de la vacante',
          text: 'Una vacante puede estar activa o inactiva. Si la barra está verde, la vacante está activa. Si está roja, está inactiva. Los candidatos solo pueden postularse a vacantes activas.'
        },
        {
          title: 'Reclutadores',
          text: 'Puedes añadir o eliminar a los reclutadores responsables de esta vacante haciendo clic en "Editar".'
        },
        {
          title: 'Candidatos',
          text: 'Aquí podrás gestionar y visualizar a los candidatos que se han postulado para esta vacante.'
        },
        {
          title: 'Compartir vacantes',
          text: 'En el botón "Compartir", encontrarás los enlaces disponibles para compartir tu vacante en cada red social.'
        },
        {
          title: 'Editar Vacante',
          text: 'En el botón “Editar”, puedes cambiar la información de la vacante, así como los reclutadores responsables de la misma.'
        },
        {
          title: 'Filtros',
          text: 'Si tienes muchas vacantes y deseas encontrarlas rápidamente, utiliza el panel de filtros.'
        },
        {
          title: 'Visualización en Listas o Tarjetas',
          text: '¿Quieres visualizar tus vacantes en otro formato? Elige aquí si prefieres verlas en tarjetas o en formato de lista.'
        }
      ]
    },
    cadidatesDB: {
      finishOnboardingButton: 'Finalizar tour por la base de candidatos',
      content: [
        {
          title: 'Candidatos',
          text: 'Esta es tu base de talentos. Todos los candidatos que se han postulado a tus vacantes estarán aquí.'
        },
        {
          title: 'Ordenar candidatos',
          text: 'Si deseas ordenar a tus candidatos para facilitar tu búsqueda, verifica aquí las opciones de ordenación.'
        },
        {
          title: 'Filtrar candidatos',
          text: '¿Muchos candidatos? Puedes usar el panel lateral para filtrar por campos específicos relacionados con la educación, experiencia y otros criterios.'
        },
        {
          title: 'Previsualización de currículum',
          text: 'No necesitas acceder individualmente a cada página de candidato. Haz clic aquí para ver un resumen de la información y facilitar tu evaluación.'
        },
        {
          title: 'Detalles del candidato',
          text: 'Si el resumen no es suficiente, puedes acceder a toda la información de este candidato haciendo clic aquí para ver el currículum completo y el historial.'
        },
        {
          title: 'Postular a una vacante',
          text: 'Si deseas añadir a este candidato a una vacante, puedes hacerlo aquí.'
        }
      ]
    },
    candidateDetail: {
      finishOnboardingButton: 'Finalizar tutorial',
      content: [
        {
          title: 'Detalle del candidato',
          text: 'Aquí encontrarás toda la información detallada sobre un candidato, incluyendo historial de postulaciones, observaciones hechas por los reclutadores y mucho más.'
        },
        {
          title: 'Historial',
          text: 'En el historial del candidato puedes revisar todas las etapas por las que ha pasado en su proceso de contratación.'
        },
        {
          title: 'Postular para la Vacante',
          text: 'Si tienes permiso para gestionar este proceso, podrás mover al candidato a otras etapas.'
        },
        {
          title: 'Evaluación del reclutador',
          text: 'Puedes revisar la calificación asignada por un reclutador. Las calificaciones son información importante durante el proceso del candidato.'
        },
        {
          title: 'Evaluar candidato',
          text: 'Para evaluar al candidato, solo utiliza las estrellas.'
        },
        {
          title: 'Notas',
          text: 'Si deseas registrar información sobre el candidato, utiliza el cuadro de texto.'
        }
      ]
    },
    candidates: {
      finishOnboardingButton: 'Finalizar tutorial',
      content: [
        {
          title: 'Candidatos',
          text: 'Estos son los candidatos que se han postulado a una vacante disponible. Aquí podrás gestionarlos para cumplir con las etapas de tu flujo de contratación.'
        },
        {
          title: 'Etapas del flujo de contratación',
          text: 'Esta barra muestra todas las etapas del Flujo de Contratación asignado a esta vacante. Aquí puedes filtrar tus candidatos por etapa. Haz clic en una etapa para ver los candidatos presentes en ella.'
        },
        {
          title: 'Ordenar candidatos',
          text: 'Si deseas ordenar a tus candidatos para facilitar tu búsqueda, verifica aquí las opciones de ordenación.'
        },
        {
          title: 'Filtrar candidatos',
          text: '¿Muchos candidatos? Puedes usar el panel lateral para filtrar por campos específicos relacionados con la educación, experiencia y otros criterios.'
        },
        {
          title: 'Previsualización de currículum',
          text: 'No necesitas acceder individualmente a cada página de candidato. Haz clic aquí para ver un resumen de la información y facilitar tu evaluación.'
        },
        {
          title: 'Detalles del candidato',
          text: 'Si el resumen no es suficiente, puedes acceder a toda la información de este candidato haciendo clic aquí para ver el currículum completo y el historial.'
        },
        {
          title: 'Mover Candidato',
          text: 'Si ya sabes a qué etapa corresponde el candidato, puedes hacerlo aquí.'
        }
      ]
    },
    companySettings: {
      finishOnboardingButton: 'Finalizar tutorial',
      content: [
        {
          title: 'Configuración de la empresa',
          text: 'Configura aquí la página de carreras de tu empresa. Esta página se utilizará para mostrar tus vacantes activas.'
        },
        {
          title: 'Datos principales',
          text: 'Empieza añadiendo los datos principales. No te preocupes, solo el nombre de la empresa, la ubicación y la descripción se mostrarán en la página de carreras.'
        },
        {
          title: 'Logo de la empresa',
          text: 'Añade el logo de la empresa para completar el encabezado de la página. Recomendamos que tenga un tamaño mínimo de 300px de ancho y que el fondo sea transparente.'
        },
        {
          title: 'Configura con los colores de la empresa',
          text: 'Configura el color del encabezado, los textos y los botones de la página de carreras.'
        },
        {
          title: 'Datos sensibles',
          text: 'Elige qué información adicional deseas recopilar de los candidatos cuando se postulen a tus vacantes.'
        }
      ]
    },
    companyTeam: {
      finishOnboardingButton:
        'Finalizar tour por la pantalla de gestión de equipo',
      content: [
        {
          title: 'Equipo de tu empresa',
          text: 'Aquí podrás gestionar los usuarios de tu empresa y definir sus permisos.'
        },
        {
          title: 'Usuarios',
          text: 'Los usuarios pueden tener perfiles de acceso como administradores, gestores o reclutadores. Solo los administradores tienen permiso para editar la configuración de la empresa, modificar los flujos de contratación y gestionar a los usuarios.'
        },
        {
          title: 'Estado del usuario',
          text: 'Un usuario puede estar activado o bloqueado, y esta barra indicará su estado. Si está verde, el usuario está activo. Si está roja, el usuario está bloqueado y no podrá acceder a la plataforma.'
        },
        {
          title: 'Bloquear o activar usuarios',
          text: 'Para bloquear o reactivar usuarios, simplemente haz clic aquí.'
        },
        {
          title: 'Editar usuario',
          text: 'Aquí puedes editar un usuario incluido en el equipo. Puedes editar el nombre, el correo electrónico, la contraseña y los permisos.'
        },
        {
          title: 'Eliminar usuario',
          text: 'Para eliminar un usuario, haz clic en el ícono de la papelera.'
        },
        {
          title: 'Registrar un nuevo usuario',
          text: 'Para añadir un nuevo usuario, solo haz clic en este botón y completa el formulario.'
        }
      ]
    },
    jobsEdit: {
      finishOnboardingButton: 'Finalizar tutorial',
      content: [
        {
          title: 'Registro/Edición de Vacantes',
          text: 'Configura aquí la información de tu vacante. Añade los datos principales para comenzar.'
        },
        {
          title: 'Vincular a un Flujo de Contratación',
          text: 'Para que tu vacante tenga un proceso en etapas definido, crea un Flujo de Contratación en “Flujos de Contratación” que encontrarás en el engranaje en la esquina superior derecha de la pantalla y, posteriormente, vincúlalo a esta vacante aquí.'
        },
        {
          title: 'Preguntas personalizadas',
          text: 'Podrás crear preguntas personalizadas para ser mostradas al candidato al momento de la inscripción. Puedes crear preguntas de opción única, múltiple elección y texto abierto. Las preguntas de opción única pueden ser marcadas como eliminatorias, moviendo al candidato automáticamente a la etapa “Propuesta rechazada” si responde incorrectamente.'
        },
        {
          title: 'Reclutadores y Gestores',
          text: 'Arrastra a los reclutadores o gestores disponibles para que formen parte del equipo del proceso de contratación de esta vacante y puedan tomar decisiones y acciones durante el proceso.'
        },
        {
          title: 'Guardar',
          text: 'Guarda los cambios realizados para concretar tu vacante.'
        }
      ]
    },
    workflow: {
      finishOnboardingButton: 'Finalizar tutorial',
      content: [
        {
          title: 'Flujos de contratación',
          text: 'Aquí podrás crear y gestionar los flujos de contratación para cada vacante. Así sabrás exactamente qué etapas deberán recorrer tus candidatos hasta la contratación.'
        },
        {
          title: 'Editar/visualizar el flujo de contratación',
          text: 'Para editar o visualizar un flujo de contratación, solo haz clic aquí.'
        },
        {
          title: 'Eliminar Flujo de Contratación',
          text: 'Para eliminar un flujo de contratación, haz clic en el ícono de la papelera. Un flujo de contratación solo puede ser eliminado si no está vinculado a ninguna vacante.'
        },
        {
          title: 'Registrar un nuevo flujo de contratación',
          text: 'Para registrar un nuevo flujo de contratación, solo haz clic en este botón.'
        }
      ],
      add: 'Añadir nuevo Flujo de Contratación',
      update: 'Actualizar Flujo de Contratación'
    },
    workflowEdit: {
      finishOnboardingButton: 'Finalizar tutorial',
      content: [
        {
          title: 'Flujo de contratación',
          text: 'Aquí podrás gestionar las etapas de tu flujo de contratación. Las etapas registradas aquí estarán disponibles para que muevas a los candidatos durante el proceso selectivo.'
        },
        {
          title: 'Etapas estándar',
          text: 'Recrud ya tiene algunas etapas estándar que no pueden ser eliminadas. La primera etapa es “Disponible”. Cada candidato que se postula a una vacante comienza en esta etapa. Las otras etapas estándar son: Rechazado en la preselección, Aprobado en la preselección, Propuesta Rechazada y Contratado.'
        },
        {
          title: 'Crear etapa',
          text: 'En Recrud puedes estructurar el proceso de selección de la manera que te resulte conveniente. Para incluir una nueva etapa, solo haz clic en "Crear nueva etapa aquí".'
        },
        {
          title: 'Etapas personalizadas',
          text: 'Las etapas personalizadas están identificadas por el color naranja.'
        },
        {
          title: 'Eliminar etapa personalizada',
          text: 'Las etapas personalizadas podrán ser eliminadas si el flujo de contratación no está vinculado a ninguna vacante.'
        },
        {
          title: 'Enviar correo automático',
          text: 'Habilita esta funcionalidad si deseas que el candidato reciba el correo configurado tan pronto como sea movido a la etapa.'
        },
        {
          title: 'Contenido del correo automático',
          text: 'El contenido del correo automático puede ser modificado haciendo clic en este botón. Puedes editarlo según prefieras.'
        }
      ]
    }
  },
  jobCard: {
    candidates: 'Candidatos',
    createNewVacancy: 'Crear nueva vacante',
    edit: 'Editar',
    move: 'Mover',
    duplicate: 'Duplicar',
    share: 'Compartir',
    team: 'Equipo',
    noteam: 'Sin equipo seleccionado',
    managers: 'Gestor',
    recruiter: 'Reclutador',
    toggleJob: 'Pausar/Reanudar vacante',
    overdueJob: 'Vacante vencida',
    closedJob: 'Vacante cerrada',
    stopJob: 'Pausar',
    startJob: 'Iniciar'
  },
  teamManagement: {
    title: 'Gestionar equipo',
    text: 'Cree y edite usuarios para su empresa',
    blockModal: {
      title: 'Confirmación',
      description: '¿Realmente desea <strong>bloquear</strong> al usuario ',
      undo: 'Cancelar',
      confirm: '¡Sí, bloquear!',
      success: '¡Usuario bloqueado con éxito!'
    },
    unblockModal: {
      title: 'Confirmación',
      description: '¿Realmente desea <strong>desbloquear</strong> al usuario ',
      undo: 'Cancelar',
      confirm: '¡Sí, desbloquear!',
      success: '¡Usuario desbloqueado con éxito!'
    },
    deleteModal: {
      title: 'Confirmación',
      description: '¿Realmente desea <strong>eliminar</strong> al usuario ',
      undo: 'Cancelar',
      confirm: '¡Sí, eliminar!',
      success: '¡Usuario eliminado con éxito!'
    },
    editModal: {
      success: '¡Usuario actualizado con éxito!'
    },
    addModal: {
      success: '¡Usuario creado con éxito!'
    }
  },
  teamCard: {
    createNewUser: 'Crear nuevo usuario',
    edit: 'Editar',
    block: 'Bloquear',
    reactivate: 'Reactivar',
    delete: 'Eliminar',
    profileOptions: {
      admin: 'Administrador',
      manager: 'Gestor',
      recruiter: 'Reclutador'
    },
    modalWorkspacesTitle: 'Workspaces vinculados',
    modalWorkspacesSubtitle:
      'Vea la lista de workspaces vinculados a este usuario.'
  },
  userModal: {
    edit: {
      title: 'Editar Usuario',
      confirm: '¡Editar Usuario!'
    },
    add: {
      title: 'Añadir Usuario',
      confirm: '¡Crear Usuario!'
    },
    firstname: 'Nombre',
    lastname: 'Apellido',
    email: 'Correo electrónico',
    password: 'Contraseña',
    profile: 'Perfil',
    profileOptions: {
      admin: 'Administrador',
      manager: 'Gestor',
      recruiter: 'Reclutador'
    },
    emailEdit:
      'Para cambiar su correo electrónico, acceda a la edición de perfil o',
    clickHere: 'haga clic aquí'
  },
  hiringStages: {
    text: 'Haga clic en una etapa para ver los candidatos que están en esa etapa.'
  },
  jobHeader: {
    firstOption: 'Ofertas atrasadas',
    secondOption: 'Activas',
    thirdOption: 'Suspendidas',
    fourthOption: 'Todas',
    searchLabel: 'Filtrar por workspaces',
    selectLabel: 'Filtrar por'
  },
  candidateHeader: {
    lugarh: {
      title: 'Base general de candidatos',
      subtitle: 'Esta es la base de currículos general de su empresa',
      filter: 'FILTRAR',
      addCandidate: 'AGREGAR CANDIDATO',
      export: 'EXPORTAR',
      applyCandidates: 'APLICAR A OFERTA',
      selectCandidates: 'Seleccionar todo',
      candidate: {
        apply: 'APLICAR A OFERTA',
        detail: 'VER CURRÍCULUM',
        preview: 'VISTA PREVIA'
      },
      candidateListHeaders: {
        name: 'NOMBRE',
        resumes: 'CURRÍCULOS',
        apply: 'APLICAR A OFERTA'
      }
    },
    title:
      "Mostrando candidatos para '{{opportunity}}' en la etapa '{{stage}}'",
    databaseTitle: 'Base de Datos de Candidatos',
    orderBy: 'Ordenar por',
    moveSelected: 'Mover seleccionados a la etapa',
    orderByOptions: {
      created_at: 'Fecha de Inclusión',
      name: 'Nombre del candidato',
      firstname: 'Nombre del candidato',
      review: 'Calificación Media',
      applicationDate: 'Fecha de Aplicación'
    }
  },
  candidateList: {
    select: 'Mover a etapa',
    review: 'Calificación Media',
    preview: 'Vista Previa',
    detail: 'Detalle',
    apply: 'Aplicar a Oferta',
    add: 'Agregar',
    addCandidate: 'Agregar Candidato'
  },
  select: {
    acquisitionChannel: {
      0: 'Seleccione'
    },
    vacancyAllocation: {
      0: 'Seleccione',
      1: 'Híbrido',
      2: 'Presencial',
      3: 'Remoto',
      4: 'No especificado'
    },
    education: {
      0: 'Educación básica incompleta',
      1: 'Educación básica',
      2: 'Educación secundaria incompleta',
      3: 'Educación secundaria',
      4: 'Superior',
      5: 'Posgrado',
      6: 'Maestría',
      7: 'Doctorado'
    },
    language: {
      0: 'Básico',
      1: 'Intermedio',
      2: 'Fluido',
      3: 'Nativo'
    }
  },
  content: {
    whatsrecrud: {
      title: '¿Qué es Recrud?',
      subtitle: 'Lorem ipsum'
    },
    privacypolicy: {
      title: 'Políticas de Privacidad',
      subtitle: 'Lorem ipsum'
    },
    terms: {
      title: 'Términos',
      subtitle: 'Lorem ipsum'
    }
  },
  candidatePreview: {
    about: 'Sobre',
    education: 'Formación',
    experience: 'Experiencia',
    languages: 'Idiomas',
    closePreview: 'Cerrar Vista Previa',
    educationIn: 'en',
    current: 'actual',
    basic: 'Básico',
    intermediate: 'Intermedio',
    fluent: 'Fluido',
    native: 'Nativo',
    single: 'Soltero',
    married: 'Casado',
    divorced: 'Divorciado',
    widowed: 'Viudo',
    other: 'Otro',
    male: 'Masculino',
    female: 'Femenino'
  },
  languageLevelOptions: {
    basic: 'Básico',
    intermediate: 'Intermedio',
    fluent: 'Avanzado',
    native: 'Nativo'
  },
  candidateFilter: {
    modalTitle: 'Filtrar Candidatos',
    getDataError:
      'Hubo un problema al buscar algunas informaciones. Por favor, intente recargar la página.',
    genderOptions: {
      other: 'Otro',
      male: 'Hombre',
      female: 'Mujer'
    },
    civilStatusOptions: {
      single: 'Soltero',
      married: 'Casado',
      divorced: 'Divorciado',
      widowed: 'Viudo',
      other: 'Otro'
    },
    availableToMoveOptions: {
      true: 'Sí',
      false: 'No'
    },
    languageLevelOptions: {
      basic: 'Básico',
      intermediate: 'Intermedio',
      fluent: 'Avanzado',
      native: 'Nativo'
    },
    educationLevelOptions: {
      incomplete_middleschool: 'Educación básica incompleta',
      middleschool: 'Educación básica completa',
      incomplete_highschool: 'Educación secundaria incompleta',
      highschool: 'Educación secundaria completa',
      undergraduate: 'Superior',
      postgraduate: 'Posgrado',
      masters: 'Maestría',
      doctorate: 'Doctorado'
    },
    buttons: {
      filter: 'Filtrar',
      filterCandidate: 'Filtrar candidatos',
      applyFilters: 'Aplicar filtros'
    },
    general: {
      title: 'General',
      clearFilters: 'Limpiar Filtros',
      location: 'Ubicación',
      age: {
        min: 'Edad mínima',
        max: 'Edad máxima'
      },
      gender: 'Género',
      civilStatus: 'Estado Civil',
      salary: {
        min: 'Salario mínimo',
        max: 'Salario máximo'
      },
      availableToMove: '¿Disponible para cambio?',
      workspacesMltiselect: 'Espacio(s) de trabajo'
    },
    education: {
      title: 'Educación',
      name: 'Institución educativa',
      course: 'Curso',
      level: 'Nivel'
    },
    experience: {
      title: 'Experiencia',
      company: 'Empresa',
      position: 'Cargo',
      timeInYears: 'Tiempo de trabajo en años'
    },
    language: {
      title: 'Idioma',
      name: 'Idioma',
      level: 'Nivel'
    }
  },
  aboutContainer: {
    goBack: 'Volver',
    about: 'Sobre mí',
    education: 'Educación',
    birthdate: 'Fecha de nacimiento',
    experience: 'Experiencia Profesional',
    otherInfo: 'Otra Información',
    languages: 'Idiomas',
    certificates: 'Certificados y Logros',
    certificateHours: '{{hours}} horas',
    personalInfo: 'Información Personal',
    yes: 'Sí',
    not: 'No',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    localization: 'Ubicación',
    maritalStatus: 'Estado Civil',
    gender: 'Género',
    salaryExpectation: 'Expectativa Salarial',
    possibilityChange: 'Posibilidad de cambio',
    attachedFiles: 'Archivos Adjuntos'
  },
  workflowCard: {
    create: 'Crear nuevo flujo de contratación',
    edit: 'Renombrar',
    view: 'Editar',
    noWorkspaces: 'Sin Workspaces',
    tooltip:
      'No puede eliminar este flujo de trabajo ya que hay vacantes vinculadas a él.',
    modalWorkspacesTitle: 'Workspaces vinculados',
    modalWorkspacesSubtitle:
      'Vea la lista de workspaces vinculados a este flujo de trabajo'
  },
  jobOpportunity: {
    addJobTitle: 'Agregar Vacante',
    addJobText:
      'Complete el formulario a continuación para agregar una nueva vacante',
    editJobTitle: 'Editar Vacante',
    editJobText: 'Editar vacante de ',
    name: 'Nombre del puesto',
    roleFamily: 'Cargos relacionados',
    roleFamilyPlaceholder: 'Cargos relacionados (Escriba para ver opciones)',
    localization: 'Ubicación del Puesto',
    amount: 'Cantidad de Vacantes',
    deadline: 'Fecha de cierre',
    vacancy_allocation: 'Asignación del Puesto',
    budget: 'Presupuesto del puesto',
    tags: 'Etiquetas del puesto',
    description: 'Descripción del puesto',
    applyJobOpportunity: 'Aplicar para el puesto',
    applyCandidate: '¡Aplicar candidato!',
    jobNameLabel: 'Nombre del Puesto',
    applyToStep: 'Aplicar en la Etapa',
    success: '¡Candidato aplicado al puesto con éxito!',
    chooseOne: 'Elija una opción',
    noJobFound: 'No se encontraron vacantes'
  },
  recruiterNotes: {
    newNote: 'Escriba los detalles adicionales aquí...',
    notes: 'Notas',
    note: 'nota',
    new: 'nueva',
    by: 'por {{name}}',
    review: 'Dé su calificación a este candidato para el puesto {{jobName}}',
    notAllowed: 'Sin permiso'
  },
  historyContainer: {
    title: 'Historial',
    description: 'de aplicaciones del candidato',
    appliedForJob: 'aplicó para el puesto',
    onDate: 'el {{date}}',
    onWorkspace: 'en el workspace'
  },
  recruiterAnswers: {
    title: 'Respuestas a las preguntas personalizadas del puesto'
  },
  job: {
    banner: {
      title: 'Registrar Puesto',
      text: ''
    },
    bannerEdit: {
      title: 'Editar puesto',
      text: 'Editar Puesto de '
    },
    updatedMessage: '¡Actualizado con éxito!',
    form: {
      name: 'Nombre del Puesto',
      location: 'Ubicación del Puesto',
      deadline: 'Fecha de cierre',
      vacancy_allocation: 'Asignación del Puesto',
      tags: 'Etiquetas del puesto',
      roleFamilyPlaceholder: 'Cargos relacionados (Escriba para ver opciones)',
      amount: 'Cantidad de puestos',
      budget: 'Presupuesto del puesto',
      status: '¿Activar puesto?',
      description: 'Descripción del Puesto'
    },
    linkWorkflow: 'Vincular a un Flujo de Contratación',
    questions: 'Preguntas personalizadas para los candidatos',
    questionsInfo:
      'Ya existen candidatos que se han postulado a este puesto, por lo que las preguntas no podrán ser modificadas.',
    recruiters: 'Reclutadores disponibles',
    recruitersTeam: 'Reclutadores del Equipo',
    managers: 'Gestores disponibles',
    managersTeam: 'Gestores del Equipo',
    save: 'Guardar Cambios!',
    creationFailed:
      'Error al crear el puesto, complete los campos obligatorios e intente nuevamente',
    creationSuccess: 'Creado con éxito',
    missingWorkflow:
      'Error al crear el puesto, seleccione un Flujo de Contratación e intente nuevamente',
    updateStatusfalse: '¡El puesto ha sido pausado con éxito!',
    updateStatustrue: '¡El puesto ha sido reanudado con éxito!',
    modalTitle: 'Confirmación',
    modalTextfalse: '¿Desea pausar el puesto ',
    modalTexttrue: '¿Desea reanudar el puesto?',
    yes: 'Sí',
    no: 'No',
    tagError: 'Se ha alcanzado el límite máximo de 34 caracteres.'
  },
  jobShare: {
    shareManually: 'Compartir manualmente',
    shareWith: 'Compartir en {{socialMedia}}',
    message:
      '<p><copy>Haga clic aquí</copy> para copiar el enlace de compartición. Luego péguelo donde desea compartir.</p>',
    url: 'O utilice esta dirección: ',
    onCopyingSuccessfully: 'Enlace copiado con éxito.'
  },
  workflowStepCard: {
    create: 'Crear nueva etapa aquí'
  },
  hiringWorkflow: {
    bannerTitle: 'Flujos de Contratación',
    bannerText: 'Administre los Flujos de Contratación de su empresa',
    addNewStage: 'Agregar nueva Etapa',
    stageName: 'Nombre de la etapa',
    stageDescription: 'Descripción de la etapa',
    sendEmailAutomatically: '¿Enviar correo automáticamente?',
    stageEmailContent: 'Contenido del correo',
    createHiringStage: 'Crear etapa de contratación',
    emailSubject: 'Asunto del correo'
  },
  listWorkflowsContainer: {
    modalTitle: 'Confirmación',
    modalText:
      '¿Desea realmente <strong>eliminar</strong> el Flujo de Contratación?',
    successDeleteMessage: '¡Flujo de Contratación eliminado con éxito!',
    successCreateMessage: '¡Flujo de Contratación creado con éxito!',
    successUpdateMessage: '¡Flujo de Contratación actualizado con éxito!',
    successHiringStageCreateMessage: '¡Etapa creada con éxito!',
    successDuplicateMessage: '¡Flujo de Contratación duplicado con éxito!',
    yes: 'Sí',
    no: 'No',
    lockedDeletion:
      'No se puede eliminar el Flujo de Contratación porque está asociado a una oferta de trabajo',
    copy: 'copia'
  },
  hiringWorkflowEdit: {
    bannerTitle: 'Flujo de Contratación para',
    bannerText: 'Edite el Flujo de Contratación',
    firstP:
      'Registre aquí el flujo del proceso de selección de su oferta. Puede asociar este flujo de trabajo a una oferta y las etapas pueden ser utilizadas para filtrar y organizar a los candidatos. Recuerde, con el flujo de contratación, sabrá en qué etapa se encuentra cada candidato en su proceso. Si tiene dudas sobre cómo registrarlo, ',
    button: 'haga clic aquí'
  },
  editWorkdflowContainer: {
    blocked: 'Etapa estándar, no se puede eliminar',
    editEmail: 'Editar correo electrónico',
    stage: 'ETAPA DEL FLUJO DE CONTRATACIÓN',
    sendEmail: '¿ENVIAR CORREO ELECTRÓNICO AUTOMÁTICO?',
    emailContent: 'CONTENIDO DEL CORREO ELECTRÓNICO',
    action: 'ACCIÓN',
    yes: 'Sí',
    no: 'No',
    confirm: 'Confirmación',
    deleteContent: '¿Desea realmente <strong>eliminar</strong> la etapa?',
    successDeleteMessage: '¡Etapa eliminada con éxito!',
    editEmailModalTitle: 'Editar correo electrónico de la etapa',
    editEmailModalSubTitle:
      'Este correo electrónico se enviará cuando el candidato sea agregado a',
    editEmailModalSubject: 'Asunto',
    editEmailModalContent: 'Cuerpo del correo electrónico',
    createStageTitle: '+ Incluir nueva Etapa',
    createStageName: 'Nombre de la etapa',
    createStageDescription: 'Descripción de la etapa',
    createStageSendEmail: '¿Enviar correo electrónico automáticamente?',
    createStageEmailContent: 'Contenido del correo electrónico',
    createStageButton: '¡Crear etapa de contratación!',
    successHiringStageCreateMessage: '¡Etapa creada con éxito!',
    successUpdateAutoSend:
      '¡Envío automático de correo electrónico actualizado con éxito!',
    successUpdateEmailContent:
      '¡Contenido del correo electrónico actualizado con éxito!',
    save: 'Guardar Cambios',
    remove: 'Eliminar',
    emailSubject: 'Asunto del correo electrónico',
    headerTitle: 'Flujo de contratación'
  },
  notFound: {
    button: 'Volver',
    content:
      '¡Parece que tomaste un desvío y encontraste un agujero negro de la web que absorbió la página que estabas buscando! Pero no entres en pánico porque puedes volver!',
    title: '¡UPS!'
  },
  customQuestions: {
    question: 'Pregunta',
    eliminate: 'Eliminatoria?',
    unique: 'Respuesta única',
    mult: 'Selección múltiple',
    opened: 'Texto libre',
    answers: 'Alternativas',
    rightAnswer: 'Opción correcta',
    required: '¡Campo obligatorio!',
    moveUp: 'Mover hacia arriba',
    moveDown: 'Mover hacia abajo',
    duplicate: 'Duplicar pregunta',
    delete: 'Eliminar pregunta'
  },
  dashboard: {
    hello: 'Hola',
    seeCompanyData: 'Ver los datos del proceso de selección de la empresa:',
    filterByWorkflow: 'Filtrar por Workflow',
    filterByRecruiter: 'Filtrar por Reclutador',
    chart01: 'Vacantes abiertas',
    chart02: 'Vacantes finalizadas',
    chart1: 'Tiempo promedio de cierre de vacantes',
    chart2: 'Tiempo promedio para finalizar la vacante por reclutador',
    chart3: 'Fuente de adquisición de candidatos',
    chart4: 'Aplicaciones de candidatos por día',
    chart5: 'Candidatos por rango de edad',
    chart6: 'Candidatos por género',
    chart7: 'Candidatos por nivel educativo',
    chart8: 'Candidatos por estado civil',
    chart9: 'Candidatos por estado',
    chart10: 'Vacantes finalizadas dentro y fuera del plazo por reclutador',
    chart11: 'Candidatos por fases en los últimos 6 meses',
    inTime: 'Dentro del plazo',
    late: 'Atrasadas',
    male: 'Masculino',
    female: 'Femenino',
    other: 'Otro',
    errorChart: 'Datos insuficientes para generar el gráfico',
    errorCompanyData: 'No se pudieron cargar los datos de la empresa',
    'Solteiro(a)': 'Soltero(a)',
    'Divorciado(a)': 'Divorciado(a)',
    'Viuvo(a)': 'Viudo(a)',
    Outro: 'Otro',
    Available: 'Disponible',
    'Rejected in Triage': 'Rechazado en la Triagem',
    'Approved in Triage': 'Aprobado en la Triagem',
    'Denied Offer': 'Rechazó la oferta',
    'Rejected during the process': 'Rechazado durante el proceso',
    'Solicitar documentos': 'Solicitar Documentos',
    'Coleta de documentos': 'Recogida de documentos',
    'No name': 'name',
    Male: 'Male',
    Female: 'Female',
    'No sex info': 'Sex information',
    'No marital status info': 'Marital status information',
    Single: 'Single',
    Married: 'Married',
    Divorced: 'Divorced',
    Widowed: 'Widowed',
    Feminino: 'Feminino',
    Masculino: 'Masculino',
    'Sem informação de sexo': 'Sem informação de sexo',
    'Sem informações do estado civil': 'Sem informações do estado civil',
    Other: 'Other',
    Hired: 'Contratado',
    errorDataChart: 'Error al recuperar datos para algunos gráficos',
    errorExpiredSession: 'Sesión expirada',
    Femenino: 'Femenino',
    Otro: 'Otro',
    'Sin información sexual': 'Sin información sexual',
    'Sin información de estado civil': 'Sin información de estado civil',
    'Soltero(a)': 'Soltero(a)',
    'Viudo(a)': 'Viudo(a)',
    'Casado(a)': 'Casado(a)'
  },
  changePassword: {
    changePassword: 'Restablecer Contraseña',
    password: 'Contraseña',
    passwordConfirmation: 'Confirmar Contraseña',
    changePasswordFail:
      'El restablecimiento falló. Su enlace para restablecer la contraseña ha expirado o es inválido',
    changePasswordSuccess: 'Contraseña restablecida con éxito'
  },
  defaultWorkflowStages: {
    defaultStage: 'Esta etapa es estándar y no puede ser eliminada.',
    'Approved in Triage': 'Aprobado en la triagem',
    Available: 'Disponible',
    'Failed in the selection process': 'Rechazado en el proceso de selección',
    'Failed in the selection processD':
      'El candidato no fue aprobado en las etapas de selección como entrevista, pruebas y dinámicas.',
    'Denied Offer': 'Rechazó la oferta',
    Hired: 'Contratado',
    'Rejected in Triage': 'Rechazado en la triagem',
    'Approved in TriageD': 'El candidato fue aprobado en la triagem.',
    AvailableD:
      'El candidato está interesado en participar en el proceso de selección.',
    'Denied OfferD': 'El candidato rechazó la propuesta o abandonó el proceso.',
    HiredD: 'El candidato fue contratado.',
    'Rejected in TriageD':
      'El candidato fue eliminado del proceso de selección.',
    'Rejected during the process': 'Rechazado durante el proceso',
    'Rejected during the processD':
      'El candidato fue rechazado durante el proceso de selección.',
    'Approved in process': 'Aprobado en el proceso',
    'Approved in processD':
      'El candidato fue aprobado en el proceso de selección.',
    'Solicitar documentos': 'Solicitar documentos',
    'Solicitar documentosD':
      'El candidato recibió un enlace para enviar los documentos.',
    'Custom Step do Rafa': 'Custom Step do Rafa'
  },
  codeConfirmationModal: {
    title: 'Confirmar nuevo correo electrónico',
    subtitle: 'Ingrese el código que recibió en su nuevo correo electrónico',
    label: 'Código',
    buttonText: 'Confirmar código'
  },
  passwordConfirmationModal: {
    title: 'Cambio de correo electrónico',
    subtitle:
      'Para cambiar su correo electrónico, por favor confirme la contraseña',
    label: 'Contraseña',
    buttonText: 'Confirmar contraseña'
  },
  candidatesListContainer: {
    notfound: 'No se encontraron registros.',
    select: 'Seleccione el perfil de contratación',
    title: 'Elija el perfil de contratación',
    button: 'Concluir',
    info: 'Ahora, seleccione el perfil de contratación para definir la documentación que el colaborador deberá enviar.',
    infoBold:
      'Al hacer clic en “concluir”, se enviará el enlace de admisión al candidato.',
    alertInfo: 'No encontró el',
    alertInfoBold: '“Perfil de Contratación”',
    alertInfo2:
      'Solicite la creación al equipo responsable o acceda a Lugarh para registrar.'
  },
  jobsContainer: {
    notfound: 'No se encontraron registros.'
  },
  searchBox: {
    loading: 'Cargando...',
    notFound: 'No se encontraron resultados'
  },
  subscriptionSettings: {
    headerSettigns: {
      headerTitle: 'Configuración de la Suscripción',
      headerText: 'Actualizar o cancelar la suscripción'
    },
    nextChargeTitle: 'Próximo Cargo',
    nextChargeDescriptionDate: 'La próxima fecha de facturación será el ',
    nextChargeDescriptionValue: 'por un monto de ',
    paymentMethodTitle: 'Método de Pago',
    paymentMethodType: 'Tarjeta de Crédito',
    paymentMethodCardNumber: 'que termina en el número',
    paymentMethodCardNumberExpiresAt: 'Expira en:',
    warningWithoutPaymentMethod: 'No hay ningún método de pago registrado.',
    descriptionWithoutPaymentMethod:
      'Puede añadir un método de pago al pagar la siguiente factura y habilitarlo para futuros pagos.',
    invoices: {
      invoicesTitle: 'Facturas',
      expiresAt: 'Fecha de vencimiento',
      value: 'Valor',
      status: 'Estado',
      link: 'Ver',
      statusPaid: 'Pagada',
      statusPending: 'Pendiente',
      statusExpired: 'Expirada',
      statusCanceled: 'Cancelada',
      statusRefunded: 'Reembolsada'
    },
    unsubscribeEmail: {
      subject: 'Solicitud%20de%20anulaci%C3%B3n',
      body: 'Hola%2C%0A%0AMe%20gustar%C3%ADa%20solicitar%20la%20anulaci%C3%B3n%20de%20las%20pr%C3%B3ximas%20facturas%20de%20la%20plataforma%20Recrud%20Reclutamiento%20y%20Selecci%C3%B3n.',
      buttonLabel: 'Cancelar la suscripción'
    }
  },
  candidateInfo: {
    years: 'años'
  },
  badgeList: {
    initialScale: 'No adherente',
    finalScale: 'Totalmente adherente',
    candidateAssessment:
      'La calificación final en estrellas se calcula como el promedio de las evaluaciones enviadas por todos los usuarios, que van desde no adherente hasta excelente. Esta calificación refleja la evaluación general del solicitante.'
  },
  recruiterList: {
    managers: 'Gerentes',
    recruiters: 'Reclutadores'
  },
  workspaces: {
    lugarh: {
      title: 'Espacios de trabajo',
      subtitle: 'Agregar, editar o eliminar espacios de trabajo de tu empresa.',
      geralWorkspaceTooltip:
        'Este es un espacio de trabajo predeterminado del sistema.',
      button: {
        create: 'Crear nuevo espacio de trabajo'
      },
      table: {
        actions: 'Acciones',
        soon: 'Próximamente',
        noCnpj: 'Sin CNPJ registrado',
        modal: {
          title: 'Usuarios vinculados',
          subtitle:
            'Ve el listado de personas vinculadas a este espacio de trabajo'
        },
        head: {
          workspaces: 'Espacios de trabajo',
          users: 'Usuarios',
          cnpj: 'CNPJ'
        },
        buttons: {
          edit: 'Editar',
          exclude: 'Eliminar'
        }
      }
    }
  }
}

export default es
